import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { RegionCodes } from './api/auth/responses/get-regions.response';
import { UserRightCodeEnum } from './api/auth/responses/user/user-rights/user-right-code.enum';
import { RouteEnum } from './routes.enum';
import { UserTypeEnum } from './user-type.enum';

export type AuthorizedUserRoutes = {
  users: {
    userType: UserTypeEnum;
    authorizedRoutes: RouteEnum[];
  }[];
};

export type AuthorizedUserView = {
  viewType: ViewType;
  allowedUserTypes: UserTypeEnum[];
  userModule?: RegionCodes[];
  menuLinksGroups: MenuLinksGroups[];
  primaryRoute: RouteEnum;
};

// export type UserModule =
//   | 'Admin'
//   | 'DDCA'
//   | 'DDIC'
//   | 'DDPA'
//   | 'DDNJ'
//   | 'Onboarding'
//   | 'CMS';
// export enum UserModule2 {
//   Admin = 'Admin',
//   DDCA = 'DDCA',
//   DDIC = 'DDIC',
//   DDPA = 'DDPA',
//   DDNJ = 'DDNJ',
//   Onboarding = 'Onboarding',
//   CMS= 'CMS',
//   None='None'
// }

export enum ViewType {
  Controller,
  ProducerView,
  BenefitAdministratorView,
  EmployerView,
  RegionView,
  ShowModulePicker,
  Onboarding,
  CMS,
  BenefitAdministratorNewBaUserView,
  MasterEmployerView
}

export type MenuLinksGroups = {
  GroupName: string;
  MenuLinks: MenuLink[];
};
export type MenuLink = {
  linkName: string;
  icon?: IconProp;
  link?: string;
  isPrimary?: boolean;
  authorizedRight?: UserRightCodeEnum[];
  authorizedUserTypes?: UserTypeEnum[];
  subMenuLinks?: MenuLink[];
  customAuthorization?: () => boolean;
};
