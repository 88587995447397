import { Component, OnInit } from '@angular/core';
import { interval, map, Observable, Subscription, switchMap, take } from 'rxjs';
import { IDocument } from 'src/app/core/models/api/documents.response';
import { IInvoiceDocument, InvoiceDocumentApiResponse } from 'src/app/core/models/invoice-documents.response';
import { AuthService } from 'src/app/core/services/http/auth/auth.service';
import { DocumentService } from 'src/app/core/services/http/document.service';
import { UserViewService } from 'src/app/core/services/user-view.service';
import { FileEvent, ShowButtons, ShowColumns } from '../documents.component';
import { ModalProps } from 'src/app/shared/components/modal/modal.component';
import { NotificationService } from 'src/app/core/services/notification.service';
import { formatDate } from 'src/app/core/helpers/date-helpers';
import saveAs from 'file-saver';
import { faTruckMedical } from '@fortawesome/free-solid-svg-icons';
import { UserRightCodeEnum } from 'src/app/core/models/api/auth/responses/user/user-rights/user-right-code.enum';
import { unescape } from 'querystring';
import { IsStringNullOrEmpty } from 'src/app/core/helpers/validation-helpers';

@Component({
  selector: 'app-employer-invoice-documents',
  template: `<ng-container *ngIf="documents$ | withLoading | async as documents">
    <ng-container *ngIf="documents.loading">
      <app-documents
        [showButtons]="showButtons"
        [modalProps]="modalProps"
        [openUploadDocumentModal]="openUploadDocumentModal"
        [showColumns]="showColumns"
        [documents$]="[]"
        [isLoading]="true"
        customTitle="Invoices"
        (documentDropped)="onUploadDocument($event)"
        (onDownloadDocumentEvent)="onDownloadDocument($event)"
        (onDeleteFileEvent)="onDeleteFile($event)"
        [showArchived]="false"
        [showViewJournal]="false">
        
      </app-documents>
    </ng-container>

    <ng-container *ngIf="progress > 0">
      <app-content-card CustomTitle="Invoice Upload Progress">
        <p>Upload in progress... {{progress}}%</p>
        <p>Process started by {{startedBy}}</p>
        <mat-progress-bar mode="determinate" value="{{progress}}"></mat-progress-bar>
        <p>
          Wait until the upload is complete before uploading another file.
        </p>
      </app-content-card>
    </ng-container>
    <ng-container *ngIf="documents.value">
      <app-documents
        [showButtons]="showButtons"
        [modalProps]="modalProps"
        [openUploadDocumentModal]="openUploadDocumentModal"
        [showColumns]="showColumns"
        customTitle="Invoices"
        headlineLabel="<b>Upload Invoice Documents</b>"
        [documents$]="documents.value"
        [isLoading]="loading"
        (documentDropped)="onUploadDocument($event)"
        (onDownloadDocumentEvent)="onDownloadDocument($event)"
        (onDeleteFileEvent)="onDeleteFile($event)"
        [showArchived]="false"
        [showViewJournal]="false">
      </app-documents>
    </ng-container>

    <ng-component *ngIf="!documents.value">
      <p>No documents found</p>
    </ng-component>
  </ng-container>`,
  styleUrls: ['./employer-invoice-documents.component.scss'],
})
export class EmployerInvoiceDocumentsComponent implements OnInit {
  userId?: string;
  file: File;
  openUploadDocumentModal: boolean;

  documents$: Observable<IDocument[]>;
  rows$: IDocument[];
  userCanUploadInvoice: boolean = false;

  showButtons: ShowButtons;
  showColumns: ShowColumns;
  modalProps: ModalProps;
  loading: boolean = false;
  progress: number = 0;
  startedBy: string;

  private progress$: Subscription;

  constructor (
    private authService: AuthService,
    private documentService: DocumentService,
    private notifcationService: NotificationService
  ) {}
  ngOnDestroy (): void {
    this.stopProgress();
  }

  ngOnInit (): void {
    this.openUploadDocumentModal = false;
    this.authService.userSession.pipe(take(1)).subscribe(session => {
      this.userId = session?.UserID.toString();
    });

    this.startPolling();

    if(this.authService.HasUserRight(UserRightCodeEnum.ManageInvoices))
    {
      this.userCanUploadInvoice = true;
    }

    this.showColumns = {
      showUserNameField: true,
      showCount: true,
      showSize: true,
      showActive: false
    };
    this.showButtons = {
      ShowArchiveRowButton: false,
      ShowUnarchiveRowButton: false,
      ShowUploadDocumentButton: this.userCanUploadInvoice,
      ShowDeleteRowButton: false,
      ShowSendNotificationButton: false,
    };

    this.modalProps = {
      OkButton: {
        Label: 'UPLOAD',
        OnAction: () => {
          if(this.progress > 0) {
            this.notifcationService.error('Upload in progress. Please wait for the upload to complete before uploading another file');
            return
          }
          if(this.file === undefined  || this.file === null){
            this.notifcationService.error('No file attached. Please attach a file and try again.');
            return;
          }
          if (this.file?.type !== 'text/plain') {
            this.notifcationService.error('Invalid file type. Please upload a txt file');
            return;
          }

          this.documentService.CheckInvoiceAlreadyExists(this.file?.name).subscribe(res => {
            if(res.Status && !IsStringNullOrEmpty(res.Message)){
              this.notifcationService.confirmation("File with same name already exists. Uploading this file will replace the existing one. Do you want to Continue?", () => {
                this.uploadFile();
              })
            }else{
              this.uploadFile();
            }
          })
        },
      },
      CloseButton: {
        OnAction: () => {
          this.openUploadDocumentModal = false;
        },
        show: false,
      },
      CanCloseModal: true,
    };
    this.updateDocuments();
  }

  private uploadFile(){
    this.loading = true;
    if (this.userId)
      this.documentService.UploadInvoiceDocument(this.file, this.userId.toString()).subscribe(res => {
        if (res.Status) {
          if(res.Message){
            this.notifcationService.success(res.Message, 0, true);
          }
        } else this.notifcationService.error('Something went wrong');
        this.loading = false;
        this.updateDocuments();
      });
  }

  private updateDocuments () {
    this.documents$ = this.documentService.GetInvoiceArchives().pipe(
      this.mapToTableRows(),
      map(res => {
        this.rows$ = res;
        return res;
      })
    );
  }

  mapToTableRows () {
    return map<InvoiceDocumentApiResponse, IDocument[]>(res => {
      return res.Data.map((document: IInvoiceDocument) => {
        return {
          FileId: document.Id,
          ProducerId: 0,
          FileName: document.FileName,
          IsCurrent: true,
          Deleted: document.Deleted,
          Archived: false,
          DeletedBy: null,
          DeletedDate: document.DeletedDate,
          Size: document.Size,
          Downloads: 0,
          UploadedBy: document.UploadedBy,
          UploadedByUserName: document.UploadedBy,
          UploadedDate: formatDate(document.UploadedDate),
        };
      });
    });
  }

  onUploadDocument (file: File) {
    this.file = file;
    this.openUploadDocumentModal = false;
  }

  onDownloadDocument (fileEvent: FileEvent) {
    this.documentService.DownloadInvoiceArchive(fileEvent.fileId).subscribe(buffer => {
      const data: Blob = new Blob([buffer], {
        type: 'text/csv;charset=utf-8',
      });
      saveAs(data, fileEvent.fileName);
    });
  }

  onDeleteFile (fileEvent: FileEvent) {
    this.notifcationService.confirmation('Do you want to delete this Invoice?', () => {
      this.documentService.DeleteInvoice(fileEvent.fileId).subscribe(res => {
        this.notifcationService.success("Invoice Deleted Successfully");
        this.updateDocuments();
      });
    });
  }

  stopProgress () {
    if (this.progress$) this.progress$.unsubscribe();
  }
  startPolling () {
    const pollingInterval = 5000;
    this.progress$ = interval(pollingInterval).pipe(
      switchMap(() => this.documentService.GetProgress())
    ).subscribe(res => {
        this.progress = res.Data.progress;
        this.startedBy = res.Data.startedBy;
        if(this.progress === 100){
          this.notifcationService.success('Invoices processing finished.');
        }
      }, error => {});
    
  }
}
