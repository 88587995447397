<app-content-card CustomTitle="Tutorials" [HasFullWidth]="false">
  <div ngClass="static_home_wrapper" >
    <div style="display: flex; gap: 20px; flex-direction: column; padding: 10px; width: 100%" >
        <div ngClass="btn-group">
            <div
              *ngFor="let button of buttons;"
              class="btn home-page-button"
              (click)="openLink(button)">
              <span>{{ button.btnText }}</span>
            </div>
          </div>
    </div>
  </div>
</app-content-card>