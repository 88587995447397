import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { faCircleQuestion, faQuestion } from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-content-card',
  styleUrls: ['./content-card.component.scss'],
  template: `
    <div ngClass="wrapper" [class.top_border]="HasTopBorder" [class.full_width]="HasFullWidth" [style.maxWidth]="MaxWidth" [style.margin]="MaxWidth ? '0 auto' : 'unset'">
      <div *ngIf="CustomTitle != ''" style="display: flex; margin-right: 10px">
        <div ngClass="title">
          <div [innerHtml]="CustomTitle"></div>
        </div>
        <div ngClass="parallelogram"></div>
        <div ngClass="question-mark" *ngIf="QuestionTitle != ''">
          <fa-icon ngClass="action_icon" [title]="QuestionTitle" [icon]="questionIcon"></fa-icon>
        </div>

      </div>
      <div ngClass="content">
        <ng-content></ng-content>
      </div>
    </div>
  `,
})
export class ContentCardComponent implements OnInit {
  constructor() {}
  @Input() CustomTitle: string = '';
  @Input() HasTopBorder: boolean = true;
  @Input() HasFullWidth: boolean = true;
  @Input() MaxWidth?: string;
  @Input() QuestionTitle: string = '';

  questionIcon = faCircleQuestion;

  ngOnInit() {

  }
}
