import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { Observable } from 'rxjs';
import { EmployerLinksReponse } from 'src/app/core/models/api/employer/employer-links.response';
import { ViewType } from 'src/app/core/models/user-view.model';
import { EmployerLinksService } from 'src/app/core/services/http/employer-links.service';
import { UserViewService } from 'src/app/core/services/user-view.service';
import { DisplayedColumns } from 'src/app/shared/components/table/table.component';
import { parseStringToRegionCode } from '../../helpers/user-view.helper';

@Component({
  selector: 'app-master-employer',
  styleUrls: ['./master-employer.component.scss'],
  template: `
    <app-content-card CustomTitle="Your Groups">
      <ng-container *ngIf="rows$ | withLoading | async as rows">
        <app-table
          [DisplayedColumns]="displayedColumns"
          [IsLoading]="rows.loading ?? false"
          [EnableSearch]="true"
          [EnableLocalSearch]="true"
          [EnableLocalSort]="true"
          [EnablePagination]="true"
          [Rows]="rows.value"
          emptyRowsTextMessage="If no companies are listed, there are no active employers at this time."
          ></app-table>
      </ng-container>
    </app-content-card>

    <ng-template #actionCol let-data>
      <div (click)="onImpersonateUser(data)" style="cursor: pointer;" [matTooltip]="'View ' + data.EmployerName + ' as ER'">
        <fa-icon ngClass="view_as_user_eye" [icon]="viewIcon"></fa-icon>
      </div>
    </ng-template>
  `,
})
export class MasterEmployerComponent implements OnInit {
  viewIcon = faEye;

  constructor (private employerLinkService: EmployerLinksService,
    private userViewService: UserViewService
  ) {}
  displayedColumns: DisplayedColumns[];
  rows$: Observable<any[]>;
  @ViewChild('actionCol', { static: true }) actionColTemplate: TemplateRef<unknown>;

  ngOnInit (): void {
    this.displayedColumns = [
      {
        columnName: 'Action',
        label: 'Action',
        template: this.actionColTemplate,
        enableFullDataColumnTemplateContext: true,
      },
      { columnName: 'EmployerNumbers', label: 'Employer Number', sortable: true },
      { columnName: 'EmployerName', label: 'Employer Name', sortable: true },

    ];

    this.loadData();
  }

  loadData () {
    this.rows$ = this.employerLinkService.GetEmployersForUser();
  }

  onImpersonateUser(employerLink: EmployerLinksReponse)
  {
    let oeMonth: number | undefined = undefined;

    if(employerLink.OEMonth !== undefined){
      oeMonth = employerLink.OEMonth;
    }
    else if(employerLink.OEMonth !== undefined){
      oeMonth = parseInt(employerLink.OEMonth);
    }
    this.userViewService
      .Propagate(false)
      .ChangeRegion(parseStringToRegionCode(this.userViewService.GetSelectedRegion()))
      .ChangeEmployerId(parseInt(employerLink.EmployerId ?? '0').toString())
      .ChangeEmployerNumber(employerLink.EmployerNumbers)
      .ChangePlanNumber(employerLink.PlanNr)
      .ChangeCompanyName(employerLink.EmployerName)
      .ChangeEmployerManageEligibility(employerLink.ManageEligibility === true)
      .ChangeWaitingPeriod(employerLink.WaitingPeriod)
      .ChangeEmployerOeMonth(oeMonth)
      .ChangeImpersonationState(true)
      .ChangeView(ViewType.EmployerView)
      .Propagate(true)
      .NavigateToPageView(this.userViewService.GetPrimaryLink().toString());

  }
}
