import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InfoFields } from '../../employee-form/employee-form.component';
import { SelectOptions } from 'src/app/shared/components/select/select.component';
import { EmployeeFormService } from '../../employee-form/employee-form.service';

@Component({
  selector: 'employee-info-form-section',
  styleUrls: ['./info-form-section.component.scss', '../styles.scss'],
  template: `
    <div ngClass="flex column flex_centered">
      <form [formGroup]="formGroup" ngClass="form_section">
        <app-form-select
          *ngIf="infoFields.qualifyingEvent.field.show"
          FieldName="qualifyingEvent"
          style="width: 300px;"
          Label="Qualifying Event"
          formControlName="qualifyingEvent"
          title="Qualifying Event"
          [parentForm]="formGroup"
          [Options]="infoFields.qualifyingEvent.qualifyingEventOptions ?? qualifyingEventOptions"></app-form-select>

        <app-form-input-date
          *ngIf="infoFields.qualifyingEventDate.show"
          fieldName="qualifyingEventDate"
          formControlName="qualifyingEventDate"
          [minDate]="minDate"
          [maxDate]="maxDate"
          [parentForm]="formGroup"
          title="Qualifying Event Date"></app-form-input-date>
        <div ngClass="first_name_with_mi">
          <app-form-input
            *ngIf="infoFields.firstname.show"
            type="text"
            [Wide]="true"
            [primary]="true"
            title="First Name"
            placeholder="First Name"
            formControlName="firstName"
            [parentForm]="formGroup"
            [forceCaps]="true"
            fieldName="firstName"></app-form-input>

          <app-form-input
            type="text"
            [Wide]="true"
            *ngIf="infoFields.mI.show"
            [width]="40"
            [primary]="true"
            title="MI"
            placeholder="MI"
            formControlName="mI"
            [parentForm]="formGroup"
            [forceCaps]="true"
            fieldName="mI"></app-form-input>
        </div>
        <app-form-input
          type="text"
          [primary]="true"
          [Wide]="true"
          *ngIf="infoFields.lastName.show"
          title="Last Name"
          placeholder="Last Name"
          formControlName="lastName"
          [parentForm]="formGroup"
          [forceCaps]="true"
          fieldName="lastName"></app-form-input>

        <app-form-input
          [type]="shouldNotViewSSN ? 'password' : 'text'"
          [primary]="true"
          [Wide]="true"
          *ngIf="infoFields.ssn.show && displayMaskedSSNOnly !== true"
          title="SSN"
          placeholder=""
          formControlName="ssn"
          [parentForm]="formGroup"
          [mask]="'000-00-0000'"
          [enableVisibilityToggle]="shouldNotViewSSN"
          fieldName="ssn"></app-form-input>
          <app-form-input
          [type]="'text'"
          [primary]="true"
          [Wide]="true"
          *ngIf="infoFields.ssn.show && displayMaskedSSNOnly === true"
          title="SSN"
          placeholder=""
          formControlName="ssn"
          [parentForm]="formGroup"
          [mask]="'AAA-AA-0000'"
          fieldName="ssn"></app-form-input>

        <div style="display: flex; gap: 10px;">
          <app-form-input-date
            title="Date Of Birth"
            *ngIf="infoFields.dateOfBirth.show"
            placeholder=""
            formControlName="dateOfBirth"
            [parentForm]="formGroup"
            fieldName="dateOfBirth"
          ></app-form-input-date>
          <!-- <app-form-input-date
            title="Date Of Birth"
            *ngIf="infoFields.dateOfBirth.show"
            placeholder=""
            formControlName="dateOfBirth"
            [parentForm]="formGroup"
            fieldName="dateOfBirth"></app-form-input-date> -->

          <app-form-radio-buttom
            [labels]="['Male', 'Female', 'Non-Binary']"
            *ngIf="infoFields.sex.show"
            checked="Male"
            title="Gender"
            formControlName="gender"
            [parentForm]="formGroup"
            fieldName="gender">
            ></app-form-radio-buttom
          >
        </div>

        <app-form-input-date
          title="Hire Date"
          *ngIf="infoFields.hireDate.show"
          placeholder=""
          [readonly]="infoFields.hireDate.readonly"
          formControlName="hireDate"
          [parentForm]="formGroup"
          fieldName="hireDate"></app-form-input-date>

        <app-form-slide-toggle
        *ngIf="infoFields.cobra.show"
          label="Cobra"
          formControlName="cobra"
          fieldName="cobra"
          [width]="100"
          [parentForm]="formGroup"></app-form-slide-toggle>

        <app-form-slide-toggle
        *ngIf="infoFields.priorCoverage.show"
          label="Prior coverage"
          formControlName="priorCoverage"
          fieldName="priorCoverage"
          [width]="250"
          [parentForm]="formGroup"></app-form-slide-toggle>

        <app-form-input-date
          *ngIf="infoFields.terminationDate.field.show && !infoFields.terminationDate.showTerminationDateAsSelect"
          title="Termination Date"
          placeholder=""
          formControlName="terminationDate"
          [readonly]="infoFields.terminationDate.field.readonly"
          [parentForm]="formGroup"
          fieldName="terminationDate"></app-form-input-date>

        <app-form-select
          *ngIf="infoFields.terminationDate.field.show && infoFields.terminationDate.showTerminationDateAsSelect"
          title="Termination Date"
          [Options]="infoFields.terminationDate.terminationDateOptions ?? []"
          placeholder=""
          formControlName="terminationDate"
          Label="Termination Date"
          [parentForm]="formGroup"
          FieldName="terminationDate"></app-form-select>

        <app-form-select
          *ngIf="infoFields.terminationReason.field.show"
          [Options]="infoFields.terminationReason.terminationReasonOptions ?? terminationReasonOptions"
          Label="Termination Reason"
          formControlName="terminationReason"
          [parentForm]="formGroup"
          FieldName="terminationReason"></app-form-select>
      </form>
    </div>
  `,
})
export class InfoFormSectionComponent implements OnInit {
  commonFormGroup: FormGroup;

  @Input() parentForm: FormGroup;
  @Input() infoFields: InfoFields;
  minDate: Date;
  maxDate: Date;
  shouldNotViewSSN: boolean = false;
  displayMaskedSSNOnly: boolean = false;
  terminationReasonOptions: SelectOptions<string>[];
  qualifyingEventOptions: SelectOptions<any>[];

  constructor(private formBuilder: FormBuilder, private employeeFormService: EmployeeFormService) {}

  ngOnInit() {
    console.log('Parent Form', this.parentForm);
    this.minDate = this.employeeFormService.GetQualifyingEventMinDate();
    this.maxDate = this.employeeFormService.GetQualifyingEventMaxDate();
    this.shouldNotViewSSN = this.employeeFormService.shouldUserNotViewSSN();
    this.displayMaskedSSNOnly = this.employeeFormService.shouldUserSeeMaskedSSNOnly();
  }

  get formGroup() {
    return this.parentForm.get('info') as FormGroup;
  }
}
