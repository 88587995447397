<form ngClass="wrapper" [formGroup]="companyProfileForm" *ngIf="pageData$ | withLoading | async as employerData">
  <div [class.is_admin]="isAdmin" style="overflow: hidden;">
    <app-content-card CustomTitle="Company Information">
      <div ngClass="company_information_wrapper">
        <app-form-input
          [parentForm]="companyProfileForm"
          [loading]="employerData.loading ?? false"
          style="grid-area: name"
          [Wide]="true"
          fieldName="companyName"
          formControlName="companyName"
          title="Company Name"></app-form-input>
        <app-form-input
          [parentForm]="companyProfileForm"
          [loading]="employerData.loading ?? false"
          [Wide]="true"
          fieldName="region"
          formControlName="region"
          title="Region"></app-form-input>
        <app-form-input
          [parentForm]="companyProfileForm"
          [loading]="employerData.loading ?? false"
          [Wide]="true"
          fieldName="groupContractState"
          title="Group Contract State"></app-form-input>
        <app-form-input-date
          *ngIf="employerData.value"
          [parentForm]="companyProfileForm"
          fieldName="originalEffectiveDate"
          formControlName="originalEffectiveDate"
          title="Original Effective Date"></app-form-input-date>

        <app-form-input
          [parentForm]="companyProfileForm"
          [loading]="employerData.loading ?? false"
          [Wide]="true"
          fieldName="Ganr"
          title="GANR">
        </app-form-input>

        <div style="display: flex; gap: 10px; flex-direction: column">
          <app-form-select
            *ngIf="showCobraType && employerData.value"
            [parentForm]="companyProfileForm"
            Label="Cobra Type"
            FieldName="cobraType"
            formControlName="cobraType"
            [Options]="cobraOptions"
            title="Cobra Type"></app-form-select>
          <span *ngIf="showCobraType && employerData.value && !isAdmin">
            To modify the COBRA type, please contact Allied support at cs@alliedadministrators.com.
          </span>
        </div>
        <div style="padding-bottom: 10px" *ngIf="isAdmin">
          <app-form-checkbox
            name="Voluntary Group"
            fieldName="VoluntaryGroup"
            [parentForm]="companyProfileForm"></app-form-checkbox>
          <app-form-checkbox
            name="New Portfolio Rates"
            fieldName="NewPortfolioRates"
            [parentForm]="companyProfileForm"></app-form-checkbox>
        </div>
      </div>
    </app-content-card>
    <app-content-card CustomTitle="Association" [HasTopBorder]="false" *ngIf="isAdmin">
      <div class="form_wrapper-3-column">
        <app-form-input
          *ngIf="isAdmin"
          [parentForm]="companyProfileForm"
          [loading]="employerData.loading ?? false"
          fieldName="masterId"
          title="Master ID">
        </app-form-input>
        <app-form-checkbox
          *ngIf="isAdmin"
          name="Association Flag"
          fieldName="associationFlag"
          [parentForm]="companyProfileForm"></app-form-checkbox>
        <app-form-checkbox
          *ngIf="isAdmin"
          name="Parent Group"
          fieldName="masterEmployer"
          [parentForm]="companyProfileForm"></app-form-checkbox>
      </div>
    </app-content-card>
    <app-content-card CustomTitle="Contact" [HasTopBorder]="false">
      <div ngClass="form_wrapper">
        <app-form-input
          [parentForm]="companyProfileForm"
          [loading]="employerData.loading ?? false"
          [Wide]="true"
          fieldName="address1"
          formControlName="address1"
          title="Address 1"></app-form-input>
        <app-form-input
          [parentForm]="companyProfileForm"
          [loading]="employerData.loading ?? false"
          [Wide]="true"
          fieldName="address2"
          title="Address 2"></app-form-input>
        <app-form-input
          [parentForm]="companyProfileForm"
          [loading]="employerData.loading ?? false"
          [Wide]="true"
          fieldName="city"
          title="City"></app-form-input>
        <app-form-select
          [parentForm]="companyProfileForm"
          Label="State"
          [Options]="stateOptions"
          FieldName="state"
          formControlName="state"
          title="State"></app-form-select>
        <div style="display: flex; gap: 10px">
          <app-form-input
            [parentForm]="companyProfileForm"
            [loading]="employerData.loading ?? false"
            [Wide]="true"
            fieldName="zip5"
            style="width: 50px"
            title="Zip"></app-form-input>
          <app-form-input
            [parentForm]="companyProfileForm"
            style="width: 50px"
            [Wide]="true"
            [loading]="employerData.loading ?? false"
            fieldName="zip4"></app-form-input>
        </div>
      </div>
    </app-content-card>
    <app-content-card CustomTitle="Billing Details" [HasTopBorder]="false">
      <div ngClass="flex_column">
        <div ngClass="form_wrapper">
          <app-form-input
            [parentForm]="companyProfileForm"
            [loading]="employerData.loading ?? false"
            [Wide]="true"
            fieldName="brokerFirstName"
            formControlName="brokerFirstName"
            title="Broker First Name">
          </app-form-input>
          <app-form-input
            [parentForm]="companyProfileForm"
            [loading]="employerData.loading ?? false"
            [Wide]="true"
            fieldName="brokerLastName"
            formControlName="brokerLastName"
            title="Broker Last Name">
          </app-form-input>
          <app-form-input
            [parentForm]="companyProfileForm"
            [loading]="employerData.loading ?? false"
            [Wide]="true"
            fieldName="billingContact"
            formControlName="billingContact"
            title="Billing Contact"></app-form-input>
          <app-form-input
            [parentForm]="companyProfileForm"
            [loading]="employerData.loading ?? false"
            [Wide]="true"
            fieldName="billingEmail"
            title="Billing Email"></app-form-input>
          <app-form-input
            [parentForm]="companyProfileForm"
            [loading]="employerData.loading ?? false"
            [Wide]="true"
            fieldName="phone"
            [mask]="'(000) 000-0000'"
            formControlName="phone"
            title="Phone"></app-form-input>
          <app-form-input
            [parentForm]="companyProfileForm"
            [loading]="employerData.loading ?? false"
            [Wide]="true"
            fieldName="fax"
            [mask]="'(000) 000-0000'"
            title="Fax"></app-form-input>
          <app-form-input
            [parentForm]="companyProfileForm"
            [loading]="employerData.loading ?? false"
            [Wide]="true"
            fieldName="newHireWaitingPeriod"
            title="New Hire Waiting Period"></app-form-input>
          <app-form-select
            [parentForm]="companyProfileForm"
            [Options]="openEnrollmentOptions"
            Label="Open Enrollment"
            FieldName="openEnrollment"
            formControlName="openEnrollment"
            title="Open Enrollment"></app-form-select>
        </div>
      </div>
    </app-content-card>
    <app-content-card
      CustomTitle="Authorized Contacts"
      QuestionTitle = "Admin Only"
      *ngIf="isAdmin"
      [HasTopBorder]="false">
      <div style="padding: 20px">
        <mat-tab-group *ngIf="employerData.value">
          <mat-tab label="Active">
            <div
              ngClass="authorized-contacts-wrapper"
              *ngFor="let item of getActiveContacts().controls; let indexOfelement = index; trackBy: trackByFn">
              <div ngClass="form_wrapper-4-column">
                <app-form-select
                  FieldName="contactType"
                  Label="Contact Type"
                  [parentForm]="getFormGroupAtIndex(getActiveContacts(), indexOfelement)"
                  [Options]="contactTypeOptions">
                </app-form-select>
                <app-form-input
                  [parentForm]="getFormGroupAtIndex(getActiveContacts(), indexOfelement)"
                  [loading]="employerData.loading ?? false"
                  [Wide]="true"
                  fieldName="name"
                  title="Name">
                </app-form-input>
                <app-form-input
                  [parentForm]="getFormGroupAtIndex(getActiveContacts(), indexOfelement)"
                  [loading]="employerData.loading ?? false"
                  [Wide]="true"
                  fieldName="email"
                  title="Email">
                </app-form-input>
                <div style="display: flex; gap: 20px; justify-content: end">
                  <custom-button
                    *ngIf="item.get('id')?.value === null"
                    [forceCapitalization]="true"
                    color="secondary"
                    label="Delete"
                    style="width: 100px; justify-self: end; align-self: center"
                    (click)="deleteContactAtIndex(indexOfelement)">
                  </custom-button>
                  <custom-button
                    [forceCapitalization]="true"
                    *ngIf="item.get('id')?.value !== null"
                    color="secondary"
                    label="Archive"
                    style="width: 100px; justify-self: end; align-self: center"
                    (click)="archiveContact(indexOfelement)">
                  </custom-button>
                </div>
              </div>
            </div>

            <div ngClass="add_button" style="padding: 10px" (click)="addNewAuthorizedContact()">
              <div>+</div>
              Add Contact
            </div>
          </mat-tab>
          <mat-tab label="Archived">
            <div
              ngClass="authorized-contacts-wrapper"
              *ngFor="let item of getArchivedContacts().controls; let indexOfelement = index; trackBy: trackByFn">
              <div ngClass="form_wrapper-4-column">
                <app-form-select
                  FieldName="contactType"
                  Label="Contact Type"
                  [parentForm]="getFormGroupAtIndex(getArchivedContacts(), indexOfelement)"
                  [Options]="contactTypeOptions">
                </app-form-select>
                <app-form-input
                  [parentForm]="getFormGroupAtIndex(getArchivedContacts(), indexOfelement)"
                  [loading]="employerData.loading ?? false"
                  [Wide]="true"
                  fieldName="name"
                  title="Name"></app-form-input>
                <app-form-input
                  [parentForm]="getFormGroupAtIndex(getArchivedContacts(), indexOfelement)"
                  [loading]="employerData.loading ?? false"
                  [Wide]="true"
                  fieldName="email"
                  title="Email">
                </app-form-input>
                <custom-button
                  [forceCapitalization]="true"
                  color="secondary"
                  label="Activate"
                  style="width: 100px; justify-self: end; align-self: center"
                  (click)="activateContact(indexOfelement)">
                </custom-button>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </app-content-card>
    <app-content-card [HasTopBorder]="false">
      <div style="display: flex; justify-content: flex-end; gap: 10px">
        <custom-button
          [forceCapitalization]="true"
          [icon]="historyIcon"
          color="secondary"
          label="VIEW JOURNAL"
          *ngIf="isAdmin"
          (onClick)="openJournal = !openJournal"></custom-button>
        <custom-button
          [forceCapitalization]="true"
          [disabled]="!companyProfileForm.dirty"
          label="SAVE"
          (onClick)="onSubmit()"></custom-button>
      </div>
    </app-content-card>
    <app-content-card [HasTopBorder]="false">
      <app-view-journal [journalInput]="journalInput" [openJournal]="openJournal"></app-view-journal>
    </app-content-card>
  </div>
  <div ngClass="flex_column gap" *ngIf="isAdmin">
    <app-content-card CustomTitle="Products">
      <div>
        <app-skeleton-input *ngIf="employerData.loading"></app-skeleton-input>
        <div *ngIf="employerData.value">
          <div *ngFor="let item of productForm.controls">
            <span
              style="font-weight: 700"
              *ngIf="item.get('vision')?.value === true && item.get('visionIndex')?.value === 0"
              >Vision</span
            >
            <app-form-checkbox
              *ngIf="item.get('vision')?.value === false"
              [name]="item.get('name')?.value"
              fieldName="status"
              [parentForm]="item"></app-form-checkbox>
            <div style="margin-left: 10px" *ngIf="item.get('vision')?.value === true">
              <app-form-checkbox
                [name]="item.get('name')?.value"
                fieldName="status"
                [parentForm]="item"></app-form-checkbox>
            </div>
            <div *ngIf="item.get('code')?.value === 'PPP'" style="margin-left: 10px">
              <app-form-checkbox
                name="Mandatory"
                fieldName="P3Mandatory"
                [parentForm]="companyProfileForm"></app-form-checkbox>
              <app-form-checkbox
                name="Voluntary"
                fieldName="P3Voluntary"
                [parentForm]="companyProfileForm"></app-form-checkbox>
            </div>
          </div>
        </div>
      </div>
    </app-content-card>
    <app-content-card CustomTitle="Manage Eligibility">
      <app-skeleton-input *ngIf="employerData.loading"></app-skeleton-input>
      <div ngClass="flex_column" style="gap: 10px; padding: 20px" *ngIf="employerData.value">
        <div ngClass="checkbox_group">
          <span>XML</span>
          <div>
            <app-form-checkbox
              name="Manage BA XML Eligibility"
              fieldName="manageBaXmlEligibility"
              [parentForm]="companyProfileForm"></app-form-checkbox>
            <app-form-checkbox
              name="Manage MP XML Eligibility"
              fieldName="manageMPXmlEligibility"
              [parentForm]="companyProfileForm"></app-form-checkbox>
            <app-form-checkbox
              name="Manage ER XML Eligibility"
              fieldName="manageErXmlEligibility"
              [parentForm]="companyProfileForm"></app-form-checkbox>
          </div>
        </div>
        <div ngClass="checkbox_group">
          <span>Eligibility Upload</span>
          <div>
            <app-form-checkbox
              name="Manage BA Eligibility Upload"
              fieldName="manageBaEligibilityUpload"
              [parentForm]="companyProfileForm"></app-form-checkbox>
            <app-form-checkbox
              name="Manage MP Eligibility Upload"
              fieldName="manageMpEligibilityUpload"
              [parentForm]="companyProfileForm"></app-form-checkbox>
            <app-form-checkbox
              name="Manage ER Eligibility Upload"
              fieldName="manageErEligibilityUpload"
              [parentForm]="companyProfileForm"></app-form-checkbox>
          </div>
        </div>
        <div ngClass="checkbox_group">
          <span>Manual</span>
          <div>
            <app-form-checkbox
              name="MPU Manage Eligibility"
              fieldName="manageEligibility"
              [parentForm]="companyProfileForm"></app-form-checkbox>
            <app-form-checkbox
              name="Manage BA Eligibility"
              fieldName="manageBaEligibility"
              [parentForm]="companyProfileForm"></app-form-checkbox>
          </div>
        </div>
      </div>
    </app-content-card>
  </div>
</form>
