import { Component, OnInit } from '@angular/core';
import { ViewType } from 'src/app/core/models/user-view.model';
import { UserViewService } from 'src/app/core/services/user-view.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['../old-front-end-styles.scss'],
})
export class HomeComponent implements OnInit {
  constructor(private viewService: UserViewService) {}

  ngOnInit() {}

  get isEmployerView() {
    return this.viewService.GetCurrentUserViewState()?.ActualView == ViewType.EmployerView || this.viewService.GetCurrentUserViewState()?.ActualView == ViewType.MasterEmployerView;
  }
}
