import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import {
  faArrowLeft,
  faCheck,
  faClockRotateLeft,
  faHouseCircleCheck,
  faPlus,
  faRotateRight,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { BehaviorSubject, debounceTime, Observable, tap } from 'rxjs';
import { EmployerLinkInput } from 'src/app/core/models/api/employer/employer-link.input';
import { EmployerLinksReponse } from 'src/app/core/models/api/employer/employer-links.response';
import { EmployersSearchResponse } from 'src/app/core/models/api/employer/employers-search.response';
import { RouteEnum } from 'src/app/core/models/routes.enum';
import { EmployerLinksService } from 'src/app/core/services/http/employer-links.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { DisplayedColumns, ManualPagination } from 'src/app/shared/components/table/table.component';
import { EmployerDocumentJournal } from 'src/app/shared/components/view-journal/view-journal.component';

@Component({
  selector: 'app-add-edit-employer-link',
  templateUrl: './add-edit-employer-link.component.html',
  styleUrls: ['./add-edit-employer-link.component.scss'],
})
export class AddEditEmployerLinkComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private employerLinkService: EmployerLinksService,
    private notificationService: NotificationService
  ) {}
  historyIcon = faClockRotateLeft;
  canAddGroups: boolean = true;
  noMasterEmployer: boolean = false;
  openJournal: boolean = false;
  journalInput: EmployerDocumentJournal;
  masterEmployerNr: string;

  customTitle: string;
  linksInfo$: Observable<EmployerLinksReponse[]>;
  newAssociation: boolean = false;
  id?: number;
  masterIdCreated: boolean = false;
  displayedColumns: DisplayedColumns[];
  employerColumns: DisplayedColumns[];
  approveIcon = faPlus;
  setMasterEmployerIcon = faHouseCircleCheck;
  reactivateEmployerIcon = faRotateRight;
  searchedText: BehaviorSubject<string> = new BehaviorSubject('');
  Pagination?: ManualPagination;
  deleteIcon = faTrash;
  arrowLeft = faArrowLeft;
  addingEmployer: boolean = false;
  tableLoading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  loading: boolean = false;
  associationUrl = RouteEnum.ManageEmployerLink;
  @ViewChild('masterIdTemplate', { static: true }) masterIdTemplate: TemplateRef<unknown>;
  @ViewChild('actionTemplate', { static: true }) actionTemplate: TemplateRef<unknown>;
  @ViewChild('employerActionTemplate', { static: true }) employerActionTemplate: TemplateRef<unknown>;
  sortColumn?: string;
  sortDirection: 'asc' | 'desc';
  tableInfo$: Observable<EmployersSearchResponse>;

  ngOnInit(): void {
    const paramId = this.route.snapshot.paramMap.get('id');

    this.employerColumns = [
      { columnName: 'EmployerNR', label: 'Employer Number', sortable: true },
      {
        columnName: 'Name',
        label: 'Company Name',
        enableFullDataColumnTemplateContext: true,
        sortable: true,
      },
      { columnName: 'ErGroupState', label: 'Group Contract State', sortable: true },
      {
        columnName: 'Action',
        label: 'Action',
        template: this.employerActionTemplate,
        enableFullDataColumnTemplateContext: true,
      },
    ];

    this.displayedColumns = [
      {
        columnName: 'Master ID',
        label: 'Master ID',
        template: this.masterIdTemplate,
        enableFullDataColumnTemplateContext: true,
      },
      {
        columnName: 'EmployerNumbers',
        label: 'Employer Number',
        sortable: true,
      },
      {
        columnName: 'MasterEmployer',
        label: 'Parent Group',
        sortable: true,
      },
      {
        columnName: 'Approved',
        label: 'Parent Group Approval',
        sortable: true,
      },
      {
        columnName: 'Active',
        label: 'Linked',
        sortable: true,
      },
      {
        columnName: 'Status',
        label: 'Request Type',
        sortable: true,
      },
      {
        columnName: 'Action',
        label: 'Action',
        template: this.actionTemplate,
        enableFullDataColumnTemplateContext: true,
      },
    ];

    if (paramId) {
      this.id = parseInt(paramId);
      this.customTitle = 'Edit Employer Association';
      this.journalInput = {
        EmployerId: this.id,
        EventType: 'EL',
      };
      this.loadData();
      this.loadConfig();
    } else {
      this.customTitle = 'Add Employer Association';
      this.newAssociation = true;
      this.addEmployer();
    }
  }

  reactivateEmployer(employerId: number){
    const input = new EmployerLinkInput();
    input.EmployerId = employerId;
    input.MasterId = this.id ?? 0;

    this.notificationService.confirmation(
      'Do you wish to proceed with reactivating this Group on this Master ID?',
      () => {
        this.loading = true;
        this.employerLinkService.ReactivateEmployer(input).subscribe(res => {
          this.loading = false;
          if(res.Status === false){
            this.notificationService.error(res.Message);

          }else{
            this.notificationService.success('Group reactivated successfully');
            this.loadData();
          }
        });
      }
    );
  }

  setMasterEmployer(employerId: number) {
    const input = new EmployerLinkInput();
    input.EmployerId = employerId;
    input.MasterId = this.id ?? 0;
    this.notificationService.confirmation(
      'Do you wish to proceed with setting this group as Parent Group for this Master ID?',
      () => {
        this.loading = true;
        this.employerLinkService.SetMasterEmployer(input).subscribe(res => {
          this.loading = false;
          this.notificationService.success('Parent Group set successfully');
          this.loadConfig();
          this.loadData();
        });
      }
    );
  }

  removeFromLink(employerId: number) {
    this.loading = true;
    const input = new EmployerLinkInput();
    input.EmployerId = employerId;
    input.MasterId = this.id ?? 0;

    this.notificationService.confirmation(
      'An email will be sent to the Master Employer contact to approve this request. Do you want to proceed?',
      () => {
        this.employerLinkService.RemoveFromLink(input).subscribe(res => {
          this.loading = false;
          this.notificationService.success('Request sent');
          this.loadData();
        });
      }
    );
  }
  addToLink(employerId: number) {
    const input = new EmployerLinkInput();
    input.EmployerId = employerId;

    input.MasterId = this.id ?? 0;

    if (input.MasterId === 0) {
      this.notificationService.confirmation(
        'This employer will be added as Master employer to this association, do you want to proceed?',
        () => {
          this.loading = true;
          this.employerLinkService.AddEmployerToLink(input).subscribe(res => {
            this.loading = false;
            if (this.newAssociation === true) {
              this.id = parseInt(res.toString());
            }
            this.masterIdCreated = true;

            this.loading = false;
            this.loadConfig();
            this.loadData();
            this.loadEmployers();
          });
        }
      , 'Confirm Master Employer');
    } else {
      this.notificationService.confirmation("Are you sure you want to add this employer to this association?", () => {
        this.employerLinkService.AddEmployerToLink(input).subscribe(res => {
          this.loading = false;
          this.loadData();
          this.loadEmployers();
        });
      });
      
    }
  }

  onSort(sort: Sort) {
    this.sortColumn = sort.active;
    this.sortDirection = sort.direction === 'asc' ? 'asc' : 'desc';

    this.tableInfo$ = this.employerLinkService
      .SearchEmployers(
        this.Pagination?.Index ?? 0,
        this.Pagination?.PageSize ?? 10,
        2,
        this.searchedText.value,
        this.sortDirection,
        this.sortColumn
      )
      .pipe(debounceTime(500));

    return true;
  }

  onSearch(searchText: string) {
    console.log(searchText);
    this.searchedText.next(searchText);
    this.tableInfo$ = this.employerLinkService
      .SearchEmployers(0, 10, 2, this.searchedText.value, this.sortDirection, this.sortColumn)
      .pipe(
        tap(res => {
          this.Pagination = { Total: res.recordsTotal, ActualPage: Number(res.draw), Index: 0, PageSize: 10 };
        })
      );
  }

  finishAdding() {
    this.addingEmployer = false;
  }

  onPaginatorEvent(event: PageEvent) {
    console.log('pagination', event);
    this.tableInfo$ = this.employerLinkService
      .SearchEmployers(event.pageIndex, event.pageSize, 2, this.searchedText.value, this.sortDirection, this.sortColumn)
      .pipe(
        debounceTime(500),
        tap(res => {
          if (this.Pagination)
            this.Pagination = {
              Index: event.pageIndex,
              ActualPage: event.pageIndex,
              Total: event.length,
              PageSize: event.pageSize,
            };
        })
      );
  }

  addEmployer() {
    this.loadEmployers();
    this.addingEmployer = true;
  }

  getMasterId(masterId: number) {
    return masterId.toString().padStart(7, '0');
  }

  loadEmployers() {
    this.tableLoading.next(true);
    this.tableInfo$ = this.employerLinkService
      .SearchEmployers(0, 10, 2, this.searchedText.value, this.sortDirection, this.sortColumn)
      .pipe(
        tap(res => {
          this.Pagination = { Total: res.recordsTotal, ActualPage: Number(res.draw), Index: 0, PageSize: 10 };
        })
      );
  }



  loadData() {
    if (!this.id) return;
    this.linksInfo$ = this.employerLinkService.GetEmployerLinkById(this.id);
    
    this.linksInfo$.subscribe(res => {
      const masterEmployer = res.filter(x => x.MasterEmployer == 'Y');
      if(masterEmployer.length === 0){
        this.noMasterEmployer = true;
      }else{
        this.masterEmployerNr = masterEmployer[0].EmployerNumbers;
        this.noMasterEmployer = false;
      }
      
    })
  }

  loadConfig(){
    if (!this.id) return;

    this.employerLinkService.GetMasterEmployerHasEmailSetup(this.id).subscribe(res => {
      this.canAddGroups = res;
    });
  }
}
