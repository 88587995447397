<div style="display: flex; justify-content: flex-end; gap: 10px">
  <custom-button
    (onClick)="generatePdf.emit(null)"
    [disabled]="isGeneratePdfDisabled || employeeFormGroup.dirty"
    [icon]="iconPDF"
    label="Export PDF">
  </custom-button>
  <custom-button
    [forceCapitalization]="true"
    [routerLink]="[employeesUrl]"
    style="align-self: flex-end; margin-bottom: 10px"
    color="secondary"
    [icon]="arrowLeft"
    label="BACK"></custom-button>
</div>
<form ngClass="wrapper" *ngIf="employerFormFields as fields" [formGroup]="employeeFormGroup" id="employee-form">
  <app-content-card [CustomTitle]="formCardFirstTitle ?? 'General Details'">
    <div style="padding: 20px" ngClass="flex column">
      <employee-info-form-section
        [infoFields]="fields.infoFields"
        [parentForm]="employeeFormGroup"></employee-info-form-section>

      <custom-button
        [forceCapitalization]="true"
        (onClick)="onReinstateEmployee.emit(employeeFormGroup)"
        *ngIf="employerFormFields.reinstateButton.show && !(viewOnly === true)"
        style="align-self: flex-start"
        [icon]="reinstateIcon"
        label="REINSTATE"></custom-button>
    </div>
  </app-content-card>

  <app-content-card CustomTitle="Contact Information" [HasTopBorder]="false">
    <div style="padding: 20px">
      <employee-contact-information-form-section
        [contactFields]="fields.infoFields"
        [parentForm]="employeeFormGroup"></employee-contact-information-form-section>
    </div>
  </app-content-card>

  <app-content-card CustomTitle="Benefits" [HasTopBorder]="false">
    <div style="padding: 20px">
      <employee-benefits-form-section
        [plans]="fields.plans"
        [parentForm]="employeeFormGroup"></employee-benefits-form-section>
      <div *ngIf="employeeFormGroup.hasError('plansError')" style="color: #ef9a9a; padding-top: 10px">
        {{ employeeFormGroup.errors?.plansError }}
      </div>
    </div>
  </app-content-card>

  <app-content-card CustomTitle="Dependent(s)" [HasTopBorder]="false">
    <div
      style="padding: 20px 50px 20px 30px"
      *ngFor="let item of dependentFormGroups.controls; let indexOfelement = index; trackBy: trackByFn">
      <div style="display: flex; gap: 20px" *ngIf="fields.dependents[indexOfelement].qualifyingEventAdd.show">
        <app-form-select
          *ngIf="fields.dependents[indexOfelement].qualifyingEvent.show"
          FieldName="qualifyingEvent"
          style="width: 300px"
          Label="Qualifying Event"
          [parentForm]="getFormGroupAtIndex(dependentFormGroups, indexOfelement)"
          [Options]="qualifyingEventDepOptions"></app-form-select>
        <app-form-input-date
          *ngIf="fields.dependents[indexOfelement].qualifyingEventDate.show"
          fieldName="qualifyingEventDate"
          [minDate]="getDependentMinQualifyingEventDate()"
          [maxDate]="getDependentMaxQualifyingEventDate()"
          [parentForm]="getFormGroupAtIndex(dependentFormGroups, indexOfelement)"
          title="Qualifying Event Date"></app-form-input-date>
      </div>
      <!-- Dependent -->
      <span ngClass="dependent_title" *ngIf="fields.dependents[indexOfelement].qualifyingEventAdd.show === false">
        Dependent - {{ indexOfelement + 1 }}
      </span>

      <div *ngIf="fields.dependents[indexOfelement].qualifyingEventAdd.show === false">
        <div ngClass="flex column" style="padding-left: 5px">
          <div ngClass="dependent_item">
            <employee-dependent-form-section
              style="flex-grow: 1"
              [parentForm]="employeeFormGroup"
              [dependent]="fields.dependents[indexOfelement]"
              [dynamicForm]="
                getFormGroupAtIndex(dependentFormGroups, indexOfelement)
              "></employee-dependent-form-section>
            <div
              title="Delete Dependent"
              style="cursor: pointer; align-self: self-start"
              (click)="deleteDependent(indexOfelement)"
              *ngIf="shouldAllowDependentDelete(dependentFormGroups, indexOfelement) && !(viewOnly === true)">
              <fa-icon [icon]="deleteIcon" style="font-size: 16px; color: #444"></fa-icon>
            </div>
            <custom-button
              [forceCapitalization]="true"
              (onClick)="onReinstateDependent.emit(getFormGroupAtIndex(dependentFormGroups, indexOfelement))"
              *ngIf="fields.dependents[indexOfelement].reinstateButton.show && !(viewOnly === true)"
              style="align-self: flex-start"
              [icon]="reinstateIcon"
              label="REINSTATE"></custom-button>
          </div>

          <!-- #region Dependent related forms -->
          <div ngClass="flex column" style="margin-left: 20px">
            <employee-benefits-form-section
              [plans]="fields.dependents[indexOfelement].plans"
              [parentForm]="getFormGroupAtIndex(dependentFormGroups, indexOfelement)"></employee-benefits-form-section>
            <div *ngIf="item.hasError('plansError')" style="color: #ef9a9a; padding-top: 10px">
              {{ item.errors?.plansError }}
            </div>
            <div style="display: flex; gap: 20px" *ngIf="fields.dependents[indexOfelement].qualifyingEvent.show">
              <app-form-select
                *ngIf="fields.dependents[indexOfelement].qualifyingEvent.show"
                FieldName="qualifyingEvent"
                style="width: 300px"
                Label="Qualifying Event"
                [parentForm]="getFormGroupAtIndex(dependentFormGroups, indexOfelement)"
                [Options]="qualifyingEventDepOptions"></app-form-select>
              <app-form-input-date
                *ngIf="fields.dependents[indexOfelement].qualifyingEventDate.show"
                placeholder="Qualifying Event Date"
                fieldName="qualifyingEventDate"
                [parentForm]="getFormGroupAtIndex(dependentFormGroups, indexOfelement)"
                [minDate]="getDependentMinQualifyingEventDate()"
                [maxDate]="getDependentMaxQualifyingEventDate()"
                title="Qualifying Event Date"></app-form-input-date>
            </div>

            <!--#region Cobra questions -->
            <div style="display: flex; gap: 20px" *ngIf="fields.dependents[indexOfelement].cobraQuestions.show">
              <app-form-radio-buttom
                title="Please confirm if dependent is COBRA eligible"
                [labels]="['Yes', 'No']"
                fieldName="isCobraEligible"
                [parentForm]="getFormGroupAtIndex(dependentFormGroups, indexOfelement)"></app-form-radio-buttom>
              <app-form-radio-buttom
                *ngIf="fields.dependents[indexOfelement].isDependentAdressSameAsEmployee.show"
                title="Please confirm the adress is the same as the employee"
                [labels]="['Yes', 'No']"
                fieldName="isDependentAdressSameAsEmployee"
                [parentForm]="getFormGroupAtIndex(dependentFormGroups, indexOfelement)"></app-form-radio-buttom>
            </div>
            <!--#endregion -->

            <employee-contact-information-form-section
              [contactFields]="fields.dependents[indexOfelement].info"
              [parentForm]="
                getFormGroupAtIndex(dependentFormGroups, indexOfelement)
              "></employee-contact-information-form-section>
          </div>
          <!--#endregion -->
        </div>
      </div>
    </div>

    <!-- Add Dependent Button -->
    <div ngClass="add_button" style="padding: 10px" *ngIf="enableAddDependent && !(viewOnly === true)">
      <div (click)="addDependent()">+</div>
      Add Dependent
    </div>

    <div ngClass="add_button" style="padding: 10px" *ngIf="enableAddQualifyingEventDependent && !(viewOnly === true)">
      <div (click)="addQualifyingEventDependent()">+</div>
      Qualifying Event - Add Dependent
    </div>

    <!-- Submit form buttons -->
    <div style="display: flex; justify-content: center; gap: 10px">
      <custom-button
        [forceCapitalization]="true"
        *ngIf="shouldAllowDeleteEmployee() && !(viewOnly === true)"
        (click)="onDeleteEmployee.emit(employeeFormGroup)"
        label="DELETE EMPLOYEE"
        color="secondary"></custom-button>

      <custom-button
        [forceCapitalization]="true"
        *ngIf="!(viewOnly === true)"
        [routerLink]="['/dashboard/employees']"
        color="secondary"
        label="CANCEL"></custom-button>
      <custom-button
        [forceCapitalization]="true"
        *ngIf="!(viewOnly === true)"
        (onClick)="onSubmit.emit(employeeFormGroup)"
        [disabled]="submitDisabled"
        label="SUBMIT"></custom-button>
      <custom-button
        [forceCapitalization]="true"
        *ngIf="shouldShowViewJournal() && !(viewOnly === true)"
        label="VIEW JOURNAL"
        [color]="'secondary'"
        [Wide]="false"
        (onClick)="openJournal = true"
        [icon]="historyIcon"></custom-button>
    </div>
    <app-view-journal [openJournal]="openJournal && editing" [journalInput]="journalInput"></app-view-journal>
  </app-content-card>
</form>

<!-- Cobra Questions -->
<div *ngIf="loading == false">
  <app-modal [modalProps]="cobraQuestionsModalProps" [open]="showCobraQuestionsModal">
    <div ngClass="flex column" style="gap: 15px; padding: 0px 20px">
      <app-form-radio-buttom
        title="Please confirm if the Employee is COBRA eligible."
        [labels]="['Yes', 'No']"
        fieldName="isCobraEligible"
        [parentForm]="employeeFormGroup"></app-form-radio-buttom>

      <app-form-radio-buttom
        *ngIf="employeeFormGroup.get('isCobraEligible')?.value == 'Yes'"
        title="Please confirm the address is current and accurate for the employee"
        [labels]="['Yes', 'No']"
        fieldName="isAddressCorrect"
        [parentForm]="employeeFormGroup"></app-form-radio-buttom>

      <div *ngIf="employeeFormGroup.get('isAddressCorrect')?.value === 'No'">
        Please go back and update the address for the Employee.
      </div>
      <div style="display: flex; justify-content: flex-end; gap: 10px">
        <custom-button
          [forceCapitalization]="true"
          *ngIf="shouldShowCobraCloseButton()"
          label="Close"
          [color]="'secondary'"
          (onClick)="showCobraQuestionsModal = false"></custom-button>

        <custom-button
          [forceCapitalization]="true"
          *ngIf="shouldShowCobraSubmitButton()"
          label="Submit"
          (onClick)="SubmitEmployeeRequest.emit(employeeFormGroup); showCobraQuestionsModal = false"></custom-button>
      </div>
    </div>
  </app-modal>

  <app-modal [modalProps]="dependentModalProps" [open]="openModalDependent">
    <p><b>You have entered a dependent who is older than 26 years.</b></p>
    submit proof of status as soon as possible or coverage will be terminated.
    <p>Please mail documents to:</p>
    <address>
      <p>Allied Administrators</p>
      <p>P.O.Box 26908</p>
      <p>San Francisco, CA 94126</p>
    </address>
    <p>or fax to: 415-439-5861</p>
    <p>To save this employee with the current information please click "Submit" in the main window.</p>
    <p>If you have entered an incorrect birthdate you can change it now and click "Submit".</p>
    <p>If you want to cancel this transaction click on "Cancel" in the main window.</p>
  </app-modal>

  <app-modal [modalProps]="priorCoverageModalProps" [open]="showPriorCoverageModal">
    <div ngClass="flex column" style="gap: 15px; padding: 0px 20px">
      <app-form-radio-buttom
        title="Please confirm if the Employee has Prior Coverage."
        [labels]="['Yes', 'No']"
        fieldName="priorCoverage"
        [parentForm]="employeeFormGroup"></app-form-radio-buttom>
        <div *ngIf="employeeFormGroup.get('priorCoverage')?.value === 'Yes'">
          <p>For Employees with prior coverage, please submit the following documents to cs@alliedadministrators.com and then they can continue to add Employee and Dependent.</p>
          <ul>
            <li>A copy of enrollee/dependent ID card.</li>
            <li>Summary of plan description.</li>
            <li>A screenshot of the prior carrier’s website showing dates of continuous coverage.</li>
          </ul>
        </div>
      <div style="display: flex; justify-content: flex-end; gap: 10px">
        <custom-button
          [forceCapitalization]="true"
          label="Close"
          *ngIf="employeeFormGroup.get('priorCoverage')?.value === 'Yes' || employeeFormGroup.get('priorCoverage')?.value === 'No'"
          [color]="'secondary'"
          (onClick)="closePriorCoverageModal()"></custom-button>
      </div>
    </div>
  </app-modal>
</div>
