<div style="display: flex; justify-content: flex-end; gap: 10px">
  <custom-button
    [forceCapitalization]="true"
    [routerLink]="[associationUrl]"
    style="align-self: flex-end; margin-bottom: 10px"
    color="secondary"
    [icon]="arrowLeft"
    label="BACK"></custom-button>
</div>
<p><b>Only use this function if you are certain a group should be associated. If you have any questions please notify your supervisor.</b></p>
<app-content-card [CustomTitle]="customTitle">
  <ng-container *ngIf="linksInfo$">
    <ng-container *ngIf="linksInfo$ | withLoading | async as linksInfo">
      <app-table
        [Rows]="linksInfo.value ?? undefined"
        [IsLoading]="linksInfo.loading ?? false"
        [DisplayedColumns]="displayedColumns"
        [EnableLocalSearch]="true"
        [EnableSearch]="true"
        [EnableLocalSort]="true">
      </app-table>
    </ng-container>
  </ng-container>
  <ng-template #masterIdTemplate let-data>
    <span>{{ getMasterId(data.MasterId) }}</span>
  </ng-template>
  <ng-template #actionTemplate let-data>
    <div ngClass="action_icons" *ngIf="data.MasterEmployer === 'N'">
      <fa-icon *ngIf="data.Active=== 'Y'"
        ngClass="action_icon"
        title="Remove Association"
        [icon]="deleteIcon"
        (click)="removeFromLink(data.EmployerId)"></fa-icon>
      <fa-icon
        *ngIf="data.Active === 'Y' && data.MasterEmployer === 'N'"
        ngClass="action_icon"
        title="Set as Parent Group"
        [icon]="setMasterEmployerIcon"
        (click)="setMasterEmployer(data.EmployerId)"></fa-icon>
        <fa-icon
        *ngIf="data.Active === 'N' && data.Approved === 'Y'"
        ngClass="action_icon"
        title="Reactivate Group"
        [icon]="reactivateEmployerIcon"
        (click)="reactivateEmployer(data.EmployerId)"></fa-icon>
    </div>
  </ng-template>
  <p *ngIf="masterIdCreated">Master Id has been created. Please assign groups to this Master ID from the list below.</p>
  <p *ngIf="noMasterEmployer"><b>No Master Employer Group setup, please select one before adding any other group to the association.</b></p>
  <p *ngIf="!canAddGroups && !noMasterEmployer" ><b>Master Employer Group ({{masterEmployerNr}}) does not have contact email setup to receive requests. Please add email address to company profile page prior to creating a Master ID.</b></p>
</app-content-card>

<div style="margin-top: 10px;"  *ngIf="addingEmployer && canAddGroups">
  <app-content-card CustomTitle="Add employer to association" *ngIf="addingEmployer && canAddGroups" [HasTopBorder]="true">
    <div style="display: flex; justify-content: flex-end; gap: 10px; margin-top: 10px">
      <custom-button
        [forceCapitalization]="true"
        (click)="finishAdding()"
        style="align-self: flex-end; margin-bottom: 10px"
        color="secondary"
        label="CLOSE"></custom-button>
    </div>
    <ng-container *ngIf="tableInfo$ | withLoading | async as tableInfo">
      <app-table
        [DisplayedColumns]="employerColumns"
        (PaginationEvent)="onPaginatorEvent($event)"
        [IsLoading]="loading"
        (onSearchEvent)="onSearch($event)"
        (onSort)="onSort($event)"
        [Pagination]="Pagination"
        [SearchDebounceTime]="800"
        [EnablePagination]="true"
        [EnableSearch]="true"
        [searchedText]="searchedText.value"
        [Rows]="tableInfo.value?.data"></app-table>
    </ng-container>
  </app-content-card>
  
</div>

<ng-template #employerActionTemplate let-data>
  <div ngClass="action_icons">
    <div *ngIf="!loading">
      <span *ngIf="data.MasterId !== null" title="Group cannot be added, already on Master ID: {{data.MasterId}}">
        {{data.MasterId}}
      </span>
      <fa-icon *ngIf="data.MasterId === null"
        ngClass="action_icon"
        title="Add to Association"
        [icon]="approveIcon"
        (click)="addToLink(data.EmployerID)"></fa-icon>
    </div>
  </div>
</ng-template>

<div style="display: flex; justify-content: flex-end; gap: 10px; margin-top: 10px" *ngIf="!addingEmployer && canAddGroups">
  <custom-button
    [forceCapitalization]="true"
    (click)="addEmployer()"
    style="align-self: flex-end; margin-bottom: 10px"
    color="secondary"
    label="ADD EMPLOYER TO ASSOCIATION"></custom-button>
</div>
<div style="display: flex; gap: 10px; align-self: flex-end" *ngIf="newAssociation === false">
  <custom-button
    [forceCapitalization]="true"
    [icon]="historyIcon"
    color="secondary"
    label="VIEW JOURNAL"
    (onClick)="openJournal = !openJournal"></custom-button>
</div>
<app-view-journal
  *ngIf="newAssociation === false"
  [journalInput]="journalInput"
  [openJournal]="openJournal"></app-view-journal>
