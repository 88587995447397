import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, from, lastValueFrom } from 'rxjs';
import { StaticPage } from 'src/app/core/helpers/view-helper';
import { AuthService } from 'src/app/core/services/http/auth/auth.service';
import { EmailService } from 'src/app/core/services/http/email.service';
import { UserViewService } from 'src/app/core/services/user-view.service';

@Component({
  selector: 'app-producer-home',
  templateUrl: './producer-home.component.html',
  styleUrls: ['./producer-home.component.scss'],
})
export class ProducerHomeComponent implements OnInit {
  staticPageAsync$: Observable<ProducerDashboardPagesHtmlAsString>;
  showAbsNewsFeed: boolean;
  pictureData: string;

  buttons = [
    { btnText: 'Tutorials', link: '/dashboard/site-navigation/tutorials', externalLink: false },
    { btnText: 'Your Clients', link: '/dashboard/master-producer-clients', externalLink: false },
    { btnText: 'Comission Statements', link: '/dashboard/commission-statements', externalLink: false },
    { btnText: 'Producer Profile', link: '/dashboard/profile', externalLink: false },
    { btnText: 'Sub Producers', link: '/dashboard/sub-producers-groups', externalLink: false },
    { btnText: 'FAQs', link: '/dashboard/site-navigation/faq', externalLink: false },
  ];
  @ViewChild('content') contentHtml: ElementRef;
  constructor(private emailService: EmailService,
    private viewService: UserViewService,
    private router: Router) {}

  ngOnInit(): void {
    this.manageButtonList();
    this.loadProducerHome();
    this.loadProducerPicture();
  }

  manageButtonList () {
     //TODO - CHECK WHICH LINKS SHOULD BE DISABLED

  }
  openLink (link:{btnText: string, link: string, externalLink: boolean} ) {
    if(link.externalLink === true)
    {
      window.open(link.link);
    }else{
      this.router.navigate([link.link]);
    }
  }
  private loadProducerPicture() {
    this.emailService.GetActivePicture().subscribe(res => {
      this.pictureData = `data:image/jpeg;base64, ${res.Data}`
    });
  }

  private loadProducerHome() {
    let producerDashboardPageIdL = this.viewService.GetUserStaticPageIdByName(StaticPage.ProducerDashboard);
    let producerDashboardPageIdR = this.viewService.GetUserStaticPageIdByName(StaticPage.ProducerDashboardR);
    if (!producerDashboardPageIdL || !producerDashboardPageIdR) return;

    this.staticPageAsync$ = from(
      this.getProducerDashboardPagesHtmlAsString(producerDashboardPageIdL, producerDashboardPageIdR)
    );
  }
  async getProducerDashboardPagesHtmlAsString(
    producerDashboardPageIdL: number,
    producerDashboardPageIdR: number
  ): Promise<ProducerDashboardPagesHtmlAsString> {
      let producerDashboardR = (await lastValueFrom(this.emailService.GetDashboard(producerDashboardPageIdR))).Data.TextEditor;
  
      return {producerDashboardL:'', producerDashboardR };

  }

  get hasABSNewFeed() {
    return this.viewService.GetCurrentUserViewState()?.ABSNewFeed;
  }
}

type ProducerDashboardPagesHtmlAsString = { producerDashboardL: string; producerDashboardR: string };
