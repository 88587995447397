import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, Form, ValidatorFn, AsyncValidatorFn } from '@angular/forms';
import { faArrowLeft, faClockRotateLeft, faFilePdf, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Observable, distinctUntilChanged, take, timeout } from 'rxjs';
import { EmployersApiResponse } from 'src/app/core/models/api/employer/employer.response';
import { BenefitsFormSection } from '../forms-sections/benefits-form-section/benefits-form-section.component';
import { ModalProps } from 'src/app/shared/components/modal/modal.component';
import { FormControlWarn, FormGroupWarn } from 'src/app/shared/components/form-elements/form-group-warn';
import { HttpClient } from '@angular/common/http';
import { SelectOptions } from 'src/app/shared/components/select/select.component';
import { IQualifyingEventOption } from '../../qualifying-event/qualifying-event.component';
import { environment } from 'src/environments/environment';
import { EmployeeControlService } from 'src/app/core/services/http/employee-control.service';
import { AuthService } from 'src/app/core/services/http/auth/auth.service';
import { EmployeeJournal } from 'src/app/shared/components/view-journal/view-journal.component';
import { EmployeeFormService } from './employee-form.service';
import { RouteEnum } from 'src/app/core/models/routes.enum';
import { NotificationService } from 'src/app/core/services/notification.service';
import { DependentChanges } from '../../add-employee/add-employee.component';
import { IsStringNullOrEmpty } from 'src/app/core/helpers/validation-helpers';
import { ModalService } from 'src/app/core/services/modal.service';
import { readJSON } from 'fs-extra';
import { disableDebugTools } from '@angular/platform-browser';

@Component({
  selector: 'app-employee-form',
  templateUrl: './employee-form.component.html',
  styleUrls: ['./employee-form.component.scss', '../forms-sections/styles.scss'],
})
export class EmployeeFormComponent implements OnInit {
  @Input() enableAddQualifyingEventDependent: boolean = false;
  journalInput: EmployeeJournal;
  employeesUrl = RouteEnum.Employees;
  arrowLeft = faArrowLeft;

  iconPDF = faFilePdf;
  addIcon = faPlus;
  deleteIcon = faTrash;
  historyIcon = faClockRotateLeft;
  reinstateIcon = faClockRotateLeft;
  employerId: string;
  employer$: Observable<EmployersApiResponse>;
  openJournal: boolean;
  adminView: boolean = false;
  openModalDependent: boolean = false;
  loading: boolean = true;

  cobraQuestionsModalProps: ModalProps = { disableButtons: true, CanCloseModal:false };
  priorCoverageModalProps: ModalProps = { disableButtons: true, CanCloseModal:false };
  dependentModalProps: ModalProps = {disableButtons: true, CloseButton:{OnAction: () => {
    this,this.modalService.openModal.next(false);
  },show: false}};
  @Input() showCobraQuestionsModal: Boolean = false;
  @Input() showPriorCoverageModal: Boolean = false;
  @Input() viewOnly: boolean = false;

  @Input() employeeFormGroup: FormGroupWarn;
  @Input() enableAddDependent: boolean;
  @Input() submitDisabled: boolean = false;
  @Input() formCardFirstTitle?: string;
  @Input() employeeReplicated: boolean = false;
  @Input() editing: boolean = false;
  @Input() employeeId: number;
  @Input() benefitsFormSection: BenefitsFormSection[] = [];
  @Input() isGeneratePdfDisabled : boolean = true;

  @Input() qualifyingEventDepOptions: SelectOptions<string>[] = [];

  @Input() employerFormFields: EmployeeFormFields;
  employerFormFieldsObs: Observable<EmployeeFormFields>;

  @Input() onAddDependent: () => Dependent;
  @Input() onQualifyingEventAddDependent: () => Dependent;

  @Output() generatePdf: EventEmitter<any> = new EventEmitter<any>();

  @Output() dependentChanges: EventEmitter<DependentChanges> = new EventEmitter<DependentChanges>();

  @Output() onCreateFormGroup: EventEmitter<FormGroupWarn> = new EventEmitter<FormGroupWarn>();

  @Output() onFormChanges: EventEmitter<FormGroupWarn> = new EventEmitter<FormGroupWarn>();

  @Output() onSubmit: EventEmitter<FormGroupWarn> = new EventEmitter<FormGroupWarn>();

  @Output() SubmitEmployeeRequest: EventEmitter<FormGroupWarn> = new EventEmitter<FormGroupWarn>();

  @Output() formFieldsChanges: EventEmitter<EmployeeFormFields> = new EventEmitter<EmployeeFormFields>();

  @Output() onReinstateEmployee: EventEmitter<FormGroupWarn> = new EventEmitter<FormGroupWarn>();
  @Output() onReinstateDependent: EventEmitter<FormGroupWarn> = new EventEmitter<FormGroupWarn>();
  @Output() onDeleteDependent: EventEmitter<string> = new EventEmitter<string>();
  @Output() onDeleteEmployee: EventEmitter<FormGroupWarn> = new EventEmitter<FormGroupWarn>();

  constructor(
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private employeeService: EmployeeControlService,
    private authService: AuthService,
    private employeeFormService: EmployeeFormService,
    private notificationService: NotificationService,
    private modalService: ModalService,
    private cdr: ChangeDetectorRef
  ) {
  }

  qualifyingEventOptions: SelectOptions<string>[] = [];

  ngAfterViewInit(): void {}

  ngOnInit() {

    this.modalService.openModal.subscribe((open) => {
      if(open){
        // this.openModalDependent = false;
        this.openModalDependent = true;

      }else{
        this.openModalDependent = false;
      }
    });

    this.employeeFormService.editing = this.editing;
    this.employeeFormService.viewOnly = this.viewOnly;
    this.employeeFormService.employeeReplicated = this.employeeReplicated;


    this.journalInput = { employeeId: this.employeeId };
    this.adminView = this.authService.IsAdmin();
    this.employerFormFieldsObs = this.employeeFormService.employeeFieldsObservable;

    this.employerFormFieldsObs.pipe(take(1)).subscribe(fields => {
      this.employeeFormGroup = new FormGroupWarn(
        {
          info: this.initInfoForm(this.employeeFormService.employeeFields.value),
          plans: this.initPlansForm(this.employeeFormService.employeeFields.value),
          dependents: this.initDependentsForm(this.employeeFormService.employeeFields.value),
          isCobraEligible: new FormControlWarn('', { updateOn: 'change' }),
          isAddressCorrect: new FormControlWarn('', { updateOn: 'change' }),
          priorCoverage: new FormControlWarn('', { updateOn: 'change' })
        },
        {
          updateOn: 'change',
          validators: this.employeeFormService.employeeFields.value.customFormValidators,
        }
        
      );
      this.onCreateFormGroup.emit(this.employeeFormGroup);

      console.log('form created!', fields);
      setTimeout(() => {
        this.loading = false;
        
      }, 100);
    });

    this.employerFormFieldsObs.subscribe(fields => {
      this.employerFormFields = fields;
      console.log('employerFormFieldUpdated', fields);
    });

    this.employeeFormGroup.valueChanges.pipe(distinctUntilChanged()).subscribe(_ => this.onFormChanges.emit(this.employeeFormGroup));

    // TODO move this to a better place
    this.http.get<IQualifyingEventOption[]>(`${environment.alliedApi.baseUrl}/QualifyingEvent`).subscribe(result => {
      result.map(option => {
        this.qualifyingEventOptions.push({
          key: option.Id.toString(),
          optionText: option.Description,
        });
      });
    });
  }

  get formA(): FormGroupWarn {
    return <FormGroupWarn>this.employeeFormGroup;
  }

  viewJournal() {
    // this.employeeService.getViewJournal()
  }

  addQualifyingEventDependent() {
    this.addDependent(true);
  }

  addDependent(qualifyingEventAdd: boolean = false) {
    let dependent: Dependent;

    if (this.isLastDependentFilled() === false) {
      this.notificationService.error('Please fill the current dependent.');
      return;
    }

    if (qualifyingEventAdd) {
      dependent = this.onQualifyingEventAddDependent();
    } else {
      dependent = this.onAddDependent();
    }
    this.employeeFormService.UpdateEmployeeFormFields(prevState => ({
      ...prevState,
      dependents: [...prevState.dependents, dependent],
    }));

    this.dependentFormGroups.push(this.createDependentControl(dependent, qualifyingEventAdd));

    let depControl = this.dependentFormGroups.at(this.employeeFormService.employeeFields.value.dependents.length - 1);

    this.dependentChanges.emit({
      index: this.employeeFormService.employeeFields.value.dependents.length - 1,
      dependent: depControl as FormGroupWarn,
      dependentCreated: true,
    } as DependentChanges);
  }

  private isLastDependentFilled(): boolean {
    if (this.employeeFormService.employeeFields.value.dependents.length === 0) return true;

    let lastDependent = this.dependentFormGroups.at(
      this.employeeFormService.employeeFields.value.dependents.length - 1
    );

    if (
      IsStringNullOrEmpty(lastDependent.get('firstName')?.value) ||
      IsStringNullOrEmpty(lastDependent.get('lastName')?.value) ||
      IsStringNullOrEmpty(lastDependent.get('birthDate')?.value) ||
      IsStringNullOrEmpty(lastDependent.get('relationshipCode')?.value)
    )
      return false;

    return true;
  }

  deleteDependent(dependentIndex: number) {
    this.notificationService.confirmation('Are you sure you want to delete this dependent?', () => {
      let dependent = this.getFormGroupAtIndex(this.dependentFormGroups, dependentIndex);
      this.dependentFormGroups.removeAt(dependentIndex);
      this.onDeleteDependent.emit(dependent.get('dependentId')?.value);

      this.employeeFormService.UpdateEmployeeFormFields(prevState => ({
        ...prevState,
        dependents: prevState.dependents.filter((_, i) => i !== dependentIndex),
      }));

    });


  }

  get dependentFormGroups(): FormArray {
    return this.employeeFormGroup.controls.dependents as FormArray;
  }

  getFormGroupAtIndex(formArray: FormArray, index: number): FormGroupWarn {
    return formArray.at(index) as FormGroupWarn;
  }

  shouldAllowDependentDelete(formArray: FormArray, index: number): boolean {
    let dependent = this.getFormGroupAtIndex(formArray, index);

    return !dependent.get('depReplicated')?.value;
  }
  shouldAllowDeleteEmployee(): boolean {
    return this.employeeReplicated === false;
  }

  shouldShowViewJournal(): boolean {
    return this.adminView === true && this.editing === true;
  }

  private initInfoForm(employerFormFields: EmployeeFormFields) {
    let { infoFields } = employerFormFields;

    return new FormGroupWarn({
      reinstated: new FormControlWarn({
        value: infoFields.reinstated.value ?? false,
        disabled: infoFields.reinstated.disable || this.viewOnly
      }),
      qualifyingEvent: new FormControlWarn(
        {
          value: infoFields.qualifyingEvent.field.value,
          disabled: infoFields.qualifyingEvent.field.disable || this.viewOnly,
        },
        infoFields.qualifyingEvent.field.validators
      ),
      qualifyingEventDate: new FormControlWarn(
        {
          value: infoFields.qualifyingEventDate.value,
          disabled: infoFields.qualifyingEventDate.disable || this.viewOnly,
        },
        infoFields.qualifyingEventDate.validators
      ),
      firstName: new FormControlWarn(
        { value: infoFields.firstname.value, disabled: infoFields.firstname.disable || this.viewOnly },
        infoFields.firstname.validators
      ),
      mI: new FormControlWarn(
        { value: infoFields.mI.value, disabled: infoFields.mI.disable || this.viewOnly },
        infoFields.mI.validators
      ),
      lastName: new FormControlWarn(
        { value: infoFields.lastName.value, disabled: infoFields.lastName.disable || this.viewOnly },
        infoFields.lastName.validators
      ),
      ssn: new FormControlWarn(
        { value: infoFields.ssn.value, disabled: infoFields.ssn.disable || this.viewOnly },
        infoFields.ssn.validators, infoFields.ssn.asyncValidators
      ),
      gender: new FormControlWarn(
        { value: infoFields.sex.value, disabled: infoFields.sex.disable || this.viewOnly },
        infoFields.sex.validators
      ),
      dateOfBirth: new FormControlWarn(
        { value: infoFields.dateOfBirth.value, disabled: infoFields.dateOfBirth.disable || this.viewOnly },
        infoFields.dateOfBirth.validators
      ),
      address: new FormControlWarn(
        { value: infoFields.address.value, disabled: infoFields.address.disable || this.viewOnly },
        infoFields.address.validators
      ),
      address2: new FormControlWarn(
        { value: infoFields.address2.value, disabled: infoFields.address2.disable || this.viewOnly },
        infoFields.address2.validators
      ),
      city: new FormControlWarn(
        { value: infoFields.city.value, disabled: infoFields.city.disable || this.viewOnly },
        infoFields.city.validators
      ),
      state: new FormControlWarn(
        { value: infoFields.state.value, disabled: infoFields.state.disable || this.viewOnly },
        infoFields.state.validators
      ),
      zip5: new FormControlWarn(
        { value: infoFields.zip5.value, disabled: infoFields.zip5.disable || this.viewOnly },
        infoFields.zip5.validators
      ),
      zip4: new FormControlWarn(
        { value: infoFields.zip4.value, disabled: infoFields.zip4.disable || this.viewOnly },
        infoFields.zip4.validators
      ),
      zip: new FormControlWarn(
        { value: infoFields.zip.value, disabled: infoFields.zip.disable || this.viewOnly },
        infoFields.zip.validators
      ),
      phone: new FormControlWarn(
        { value: infoFields.phone.value, disabled: infoFields.phone.disable || this.viewOnly },
        infoFields.phone.validators
      ),
      email: new FormControlWarn(
        { value: infoFields.email?.value, disabled: infoFields.email?.disable || this.viewOnly },
        infoFields.email?.validators
      ),
      cobra: new FormControlWarn(
        { value: infoFields.cobra.value, disabled: infoFields.cobra.disable || this.viewOnly },
        infoFields.cobra.validators
      ),
      hireDate: new FormControlWarn(
        { value: infoFields.hireDate.value, disabled: infoFields.hireDate.disable || this.viewOnly },
        infoFields.hireDate.validators
      ),
      terminationReason: new FormControlWarn(
        {
          value: infoFields.terminationReason.field.value,
          disabled: infoFields.terminationReason.field.disable || this.viewOnly,
        },
        infoFields.terminationReason.field.validators
      ),
      terminationDate: new FormControlWarn(
        {
          value: infoFields.terminationDate.field.value,
          disabled: infoFields.terminationDate.field.disable || this.viewOnly
        },
        infoFields.terminationDate.field.validators
      ),
      OE: new FormControlWarn(
        { value: infoFields.OE.value, disabled: infoFields.OE.disable || this.viewOnly },
        infoFields.OE.validators
      ),
      employernr: new FormControlWarn(
        { value: infoFields.employernr.value, disabled: infoFields.employernr.disable || this.viewOnly },
        infoFields.employernr.validators
      ),
      isCobraEligible: new FormControlWarn(
        { value: infoFields.cobraEligible.value, disabled: infoFields.cobraEligible.disable || this.viewOnly },
        infoFields.cobraEligible.validators
      ),
      isAddressCorrect: new FormControlWarn(
        { value: infoFields.addressCorrect.value, disabled: infoFields.addressCorrect.disable || this.viewOnly },
        infoFields.addressCorrect.validators
      ),
      priorCoverage: new FormControlWarn(
        { value: infoFields.priorCoverage.value, disabled: infoFields.priorCoverage.disable || this.viewOnly },
        infoFields.priorCoverage.validators
      )
    });
  }

  private initDependentsForm(employerFormFields: EmployeeFormFields) {
    let { dependents } = employerFormFields;
    let dependentArray: FormArray = this.formBuilder.array([]);

    dependents.forEach((dependent, index) => {
      let depForm = this.createDependentControl(dependent);
      dependentArray.push(depForm);
      this.dependentChanges.emit({ index: index, dependent: depForm, dependentCreated: false } as DependentChanges);
    });
    return dependentArray;
  }

  createDependentPlanControl(plan: Plan) {
    return new FormGroupWarn({
      replicated: new FormControlWarn(
        { value: plan.replicated.value, disabled: plan.replicated.disable || this.viewOnly },
        plan.replicated.validators
      ),
      productCode: new FormControlWarn(
        { value: plan.productCode.value, disabled: plan.productCode.disable || this.viewOnly },
        plan.productCode.validators
      ),
      productName: new FormControlWarn(
        { value: plan.productName.value, disabled: plan.productName.disable || this.viewOnly },
        plan.productName.validators
      ),
      effectiveDate: new FormControlWarn(
        { value: plan.effectiveDate.value, disabled: plan.effectiveDate.disable || this.viewOnly },
        plan.effectiveDate.validators
      ),
      waiverDate: new FormControlWarn(
        { value: plan.waiverDate.value, disabled: plan.waiverDate.disable || this.viewOnly },
        plan.waiverDate.validators
      ),
      terminationDate: new FormControlWarn(
        { value: plan.terminationDate.field.value, disabled: plan.terminationDate.field.disable || this.viewOnly },
        plan.terminationDate.field.validators
      ),
      dentalOffice: new FormControlWarn(
        { value: plan.dentalOffice.value, disabled: plan.dentalOffice.disable || this.viewOnly },
        plan.dentalOffice.validators
      ),
      doNotEnroll: new FormControlWarn(
        { value: plan.doNotEnroll.value, disabled: plan.doNotEnroll.disable || this.viewOnly },
        plan.doNotEnroll.validators
      ),
    });
  }

  private createDependentControl(dependent: Dependent, qualifyingEventAdd: boolean = false) {
    let plansArray: FormArray = this.formBuilder.array([]);
    dependent.plans.forEach(plan => plansArray.push(this.createDependentPlanControl(plan)));

    return new FormGroupWarn({
      qualifyingEventAdd: new FormControlWarn({
        value: qualifyingEventAdd,
        disabled: qualifyingEventAdd === false,
      }),
      qualifyingEvent: new FormControlWarn(
        {
          disabled: dependent.qualifyingEvent.disable || this.viewOnly,
          value: dependent.qualifyingEvent.value,
        },
        dependent.qualifyingEvent.validators
      ),
      qualifyingEventDate: new FormControlWarn(
        {
          disabled: dependent.qualifyingEventDate.disable || this.viewOnly,
          value: dependent.qualifyingEventDate.value,
        },
        dependent.qualifyingEventDate.validators
      ),
      //Dependent Info
      dependentId: new FormControlWarn(
        { value: dependent.dependentId.value, disabled: dependent.dependentId.disable || this.viewOnly },
        dependent.dependentId.validators
      ),
      firstName: new FormControlWarn(
        { value: dependent.firstName.value, disabled: dependent.firstName.disable || this.viewOnly },
        dependent.firstName.validators
      ),
      relationshipCode: new FormControlWarn(
        { value: dependent.relationshipCode.value, disabled: dependent.relationshipCode.disable || this.viewOnly },
        dependent.relationshipCode.validators
      ),
      lastName: new FormControlWarn(
        { value: dependent.lastName.value, disabled: dependent.lastName.disable || this.viewOnly },
        dependent.lastName.validators
      ),
      birthDate: new FormControlWarn(
        { value: dependent.birthDate.value, disabled: dependent.birthDate.disable || this.viewOnly },
        dependent.birthDate.validators
      ),
      status: new FormControlWarn(
        { value: dependent.status.value, disabled: dependent.status.disable || this.viewOnly },
        dependent.status.validators
      ),
      terminationDate: new FormControlWarn(
        {
          value: dependent.terminationDate.field.value,
          disabled: dependent.terminationDate.field.disable || this.viewOnly,
        },
        dependent.terminationDate.field.validators
      ),
      terminationReason: new FormControlWarn(
        {
          value: dependent.terminationReason.field.value,
          disabled: dependent.terminationReason.field.disable || this.viewOnly,
        },
        dependent.terminationReason.field.validators
      ),
      depReplicated: new FormControlWarn(
        { value: dependent.depReplicated.value, disabled: dependent.depReplicated.disable || this.viewOnly },
        dependent.depReplicated.validators
      ),
      reinstateButton: new FormControlWarn(
        { value: dependent.reinstateButton.value, disabled: dependent.reinstateButton.disable || this.viewOnly },
        dependent.reinstateButton.validators
      ),
      // Plans Info
      plans: plansArray,

      // COBRA Questions
      cobraQuestions: new FormControlWarn({
        value: dependent.cobraQuestions.value,
        disabled: dependent.cobraQuestions.disable || this.viewOnly,
      }),
      isDependentAdressSameAsEmployee: new FormControlWarn(
        {
          value: dependent.isDependentAdressSameAsEmployee.value,
          disabled: dependent.isDependentAdressSameAsEmployee.disable || this.viewOnly,
        },
        dependent.isDependentAdressSameAsEmployee.validators
      ),
      isCobraEligible: new FormControlWarn(
        { value: dependent.isCobraEligible.value, disabled: dependent.isCobraEligible.disable || this.viewOnly },
        dependent.isCobraEligible.validators
      ),
      reinstated: new FormControlWarn(
        { value: dependent.Reinstated.value, disabled: dependent.Reinstated.disable || this.viewOnly },
        dependent.Reinstated.validators
      ),

      // Contact Dependent Info
      info: new FormGroup({
        phone: new FormControlWarn(
          { value: dependent.info.phone.value, disabled: dependent.info.phone.disable || this.viewOnly },
          dependent.info.phone.validators
        ),
        zip5: new FormControlWarn(
          { value: dependent.info.zip5.value, disabled: dependent.info.zip5.disable || this.viewOnly },
          dependent.info.zip5.validators
        ),
        zip4: new FormControlWarn(
          { value: dependent.info.zip4.value, disabled: dependent.info.zip4.disable || this.viewOnly },
          dependent.info.zip4.validators
        ),
        state: new FormControlWarn(
          { value: dependent.info.state.value, disabled: dependent.info.state.disable || this.viewOnly },
          dependent.info.state.validators
        ),
        city: new FormControlWarn(
          { value: dependent.info.city.value, disabled: dependent.info.city.disable || this.viewOnly },
          dependent.info.city.validators
        ),
        address: new FormControlWarn(
          { value: dependent.info.address.value, disabled: dependent.info.address.disable || this.viewOnly },
          dependent.info.address.validators
        ),
        address2: new FormControlWarn(
          { value: dependent.info.address2.value, disabled: dependent.info.address2.disable || this.viewOnly },
          dependent.info.address2.validators
        ),
      }),
    });
  }

  getDependentMinQualifyingEventDate() {
    return this.employeeFormService.getDependentMinQualifyingEventDate();
  }

  getDependentMaxQualifyingEventDate() {
    return this.employeeFormService.getDependentMaxQualifyingEventDate();
  }

  shouldShowCobraCloseButton() {
    if (
      IsStringNullOrEmpty(this.employeeFormGroup.get('isCobraEligible')?.value) ||
      IsStringNullOrEmpty(this.employeeFormGroup.get('isAddressCorrect')?.value)
    )
      return false;

    return (
      this.employeeFormGroup.get('isCobraEligible')?.value === 'Yes' &&
      this.employeeFormGroup.get('isAddressCorrect')?.value === 'No'
    );
  }
  shouldShowCobraSubmitButton() {
    if (
      IsStringNullOrEmpty(this.employeeFormGroup.get('isCobraEligible')?.value) &&
      IsStringNullOrEmpty(this.employeeFormGroup.get('isAddressCorrect')?.value)
    )
      return false;

    return (
      (this.employeeFormGroup.get('isAddressCorrect')?.value === 'Yes' &&
        this.employeeFormGroup.get('isCobraEligible')?.value === 'Yes') ||
      this.employeeFormGroup.get('isCobraEligible')?.value === 'No'
    );
  }
  closePriorCoverageModal(){
    this.showPriorCoverageModal = false;
    let priorCoverageInfo = this.employeeFormGroup.get(['info','priorCoverage']);

    priorCoverageInfo?.setValue(this.employeeFormGroup.get('priorCoverage')?.value === 'Yes' ? true : false)
  }

  trackByFn(index: number, item: any) {
    return index;
  }

  private initPlansForm(employerFormFields: EmployeeFormFields) {
    let { plans } = employerFormFields;
    let plansArray: FormArray = this.formBuilder.array([]);

    plans.forEach(plan => {
      plansArray.push(
        new FormGroupWarn({
          replicated: new FormControlWarn(
            { value: plan.replicated.value, disabled: plan.replicated.disable || this.viewOnly },
            plan.replicated.validators
          ),
          productCode: new FormControlWarn(
            { value: plan.productCode.value, disabled: plan.productCode.disable || this.viewOnly },
            plan.productCode.validators
          ),
          productName: new FormControlWarn(
            { value: plan.productName.value, disabled: plan.productName.disable || this.viewOnly },
            plan.productName.validators
          ),
          effectiveDate: new FormControlWarn(
            { value: plan.effectiveDate.value, disabled: plan.effectiveDate.disable || this.viewOnly },
            plan.effectiveDate.validators
          ),
          waiverDate: new FormControlWarn(
            { value: plan.waiverDate.value, disabled: plan.waiverDate.disable || this.viewOnly },
            plan.waiverDate.validators
          ),
          terminationDate: new FormControlWarn(
            { value: plan.terminationDate.field.value, disabled: plan.terminationDate.field.disable || this.viewOnly },
            plan.terminationDate.field.validators
          ),
          dentalOffice: new FormControlWarn(
            { value: plan.dentalOffice.value, disabled: plan.dentalOffice.disable || this.viewOnly },
            plan.dentalOffice.validators
          ),
          doNotEnroll: new FormControlWarn(
            { value: plan.doNotEnroll.value, disabled: plan.doNotEnroll.disable || this.viewOnly },
            plan.doNotEnroll.validators
          ),
        })
      );
    });
    return plansArray;
  }
}

export type EmployeeFormFields = {
  infoFields: InfoFields;
  dependents: Dependent[];
  plans: Plan[];
  customFormValidators: ValidatorFn[];
  reinstateButton: Field<boolean>;
};

export type InfoFields = BasicFields & ContactFields;

export type BasicFields = {
  firstname: Field<string>;
  mI: Field<string>;
  lastName: Field<string>;
  ssn: Field<string>;
  sex: Field<string>;
  dateOfBirth: Field<Date>;
  cobra: Field<boolean>;
  hireDate: Field<Date>;
  reinstated: Field<boolean>;
  qualifyingEvent: {
    field: Field<number>;
    qualifyingEventOptions?: SelectOptions<any>[];
  };
  qualifyingEventDate: Field<Date>;
  terminationReason: {
    field: Field<string>;
    terminationReasonOptions?: SelectOptions<string>[];
  };
  terminationDate: {
    field: Field<Date>;
    showTerminationDateAsSelect: boolean;
    terminationDateOptions?: SelectOptions<string>[];
  };
  OE: Field<boolean>;
  employernr: Field<string>;
  cobraEligible: Field<boolean>;
  addressCorrect: Field<boolean>;
  priorCoverage: Field<boolean>;
};

export type ContactFields = {
  address2: Field<string>;
  address: Field<string>;
  city: Field<string>;
  state: Field<string>;
  zip5: Field<string>;
  zip4: Field<string>;
  zip: Field<string>;
  phone: Field<string>;
  email?: Field<string>;
};

export type Plan = {
  productCode: Field<string>;
  productName: Field<string>;
  effectiveDate: Field<Date>;
  dentalOffice: Field<string>;
  waiverDate: Field<Date>;
  terminationDate: {
    field: Field<string>;
    showTerminationDateAsSelect: boolean;
    terminationDateOptions?: SelectOptions<string>[];
  };
  doNotEnroll: Field<boolean>;
  replicated: Field<boolean>;
};

export type Dependent = {
  dependentId: Field<number>;
  info: ContactFields;
  isCobraEligible: any;
  isDependentAdressSameAsEmployee: any;
  firstName: Field<string>; // ok
  relationshipCode: Field<string>; // ok
  lastName: Field<string>; // ok
  birthDate: Field<Date>; // ok
  plans: Plan[];
  status: Field<string>; //
  terminationReason: {
    field: Field<string>;
    terminationReasonOptions?: SelectOptions<string>[];
  };
  terminationDate: {
    field: Field<Date>;
    showTerminationDateAsSelect: boolean;
    terminationDateOptions?: SelectOptions<string>[];
  };
  cobraQuestions: Field<boolean>;
  depReplicated: Field<boolean>;
  reinstateButton: Field<boolean>;
  Reinstated: Field<boolean>;
  qualifyingEventAdd: Field<boolean>;
  qualifyingEvent: Field<string>;
  qualifyingEventDate: Field<Date>;
};

export type Field<T> = {
  show: boolean;
  disable: boolean;
  readonly?: boolean;
  value?: T;
  validators?: ValidatorFn[];
  asyncValidators?: AsyncValidatorFn[];
};
