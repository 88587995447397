import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Observable, map } from 'rxjs';
import { DisplayedColumns } from '../table/table.component';
import { formatDate } from 'src/app/core/helpers/date-helpers';
import { ViewUserJournalResponse } from 'src/app/core/models/api/user/view-user-journal.reponse';
import { UserControlService } from 'src/app/core/services/http/user-control.service';
import { AuthenticationUserType } from 'src/app/core/models/api/auth/AuthenticationUserTypeEnum';
import { EmployeeControlService } from 'src/app/core/services/http/employee-control.service';
import { EmployerService } from 'src/app/core/services/http/employer.service';
import { ViewEmployerJournalData } from 'src/app/core/models/api/employer/view-employer-journal.reponse';
import { MarkertingResourceService } from 'src/app/core/services/http/markerting-resource.service';
import { MarketingResourceApiResponse } from 'src/app/core/models/api/marketing-resource/marketing-resource-api.response';
import { MarketingResourceJournalApiResponse } from 'src/app/core/models/api/marketing-resource/marketing-resource-journal.response';
import { DocumentService } from 'src/app/core/services/http/document.service';
import { ViewJournalResponse } from 'src/app/core/models/api/view-journal.response';
import { EmployerLinksService } from 'src/app/core/services/http/employer-links.service';

@Component({
  selector: 'app-view-journal',
  templateUrl: './view-journal.component.html',
  styleUrls: ['./view-journal.component.scss'],
})
export class ViewJournalComponent implements OnInit {
  isEmployerObject: boolean;
  isEmployeeObject: boolean;
  isMarketingResourceJournal: boolean;
  isMasterEmployerJournal: boolean;

  FormatDate(date: string) {
    return formatDate(date);
  }
  journalData$: Observable<any>;
  displayedColumns: DisplayedColumns[];

  userLoggedObject: boolean = false;

  DATA_UPDATED: string = 'Data Updated';

  @Input() UserId: number;
  @Input() userType: AuthenticationUserType;
  @Input() journalInput: EmployeeJournal | EmployerJournal | UserJournal | MarketingResourceJournal | EmployerDocumentJournal | ProducerDocumentJournal;

  @Input() openJournal: boolean = false;

  // Columns Templates
  @ViewChild('loggedInTemplate', { static: true }) loggedInTemplate: TemplateRef<unknown>;
  @ViewChild('actionOnTemplate', { static: true }) actionOnTemplate: TemplateRef<unknown>;
  @ViewChild('timeTemplate', { static: true }) timeTemplate: TemplateRef<unknown>;

  constructor(
    private userControlService: UserControlService,
    private employeeControlService: EmployeeControlService,
    private employerService: EmployerService,
    private markertingResourceService: MarkertingResourceService,
    private documentService: DocumentService,
    private employerLinkService: EmployerLinksService
  ) {}

  ngOnInit() {
    this.displayedColumns = [
      { columnName: 'Time', label: 'Time', template: this.timeTemplate },
      { columnName: 'EventType', label: 'Event Type' },
      { columnName: 'Title', label: 'Event' },
      {
        columnName: 'action_on',
        label: 'Action On',
        template: this.actionOnTemplate,
        enableFullDataColumnTemplateContext: true,
      },
      {
        columnName: 'logged_in',
        label: 'Logged In',
        template: this.loggedInTemplate,
        enableFullDataColumnTemplateContext: true,
      },
      { columnName: 'Event', label: 'Detail' },
    ];

    this.bindJournal();
  }

  private bindJournal() {

    if(isMasterEmployerJournal(this.journalInput)){
      this.isMasterEmployerJournal = true;
      this.displayedColumns.splice(3, 1); // Removes "Action On" Column
      this.journalData$ = this.employerLinkService.GetMasterIdLogs(this.journalInput.EmployerId)
      .pipe(map(res => this.cleanFields(res)));
    }
    else if(isNotifyGroupJournal(this.journalInput)){
      this.displayedColumns.splice(3, 1); // Removes "Action On" Column
      this.displayedColumns.splice(3, 1); // Removes "Action On" Column

      this.journalData$ = this.documentService.GetEmployerDocumentsViewJournal(this.journalInput.EmployerId)
      .pipe(map(res => this.cleanDocumentFields(res)));
    }
    else if(isNotifyBrokerJournal(this.journalInput)){
      this.displayedColumns.splice(3, 1); // Removes "Action On" Column
      this.displayedColumns.splice(3, 1);
      this.journalData$ = this.documentService.GetProducerDocumentsViewJournal(this.journalInput.ProducerId)
      .pipe(map(res => this.cleanDocumentFields(res)));
    }
    else if (isEmployeeJournal(this.journalInput)) {
      this.isEmployeeObject = true;
      this.journalData$ = this.employeeControlService
        .getViewJournal(this.journalInput.employeeId)
        .pipe(map(res => this.cleanFields(res.response)));
    }
    else if (isUserJournal(this.journalInput)) {
      this.userLoggedObject = true; 
      this.journalData$ = this.userControlService
        .ViewJournal(this.journalInput.UserId, this.journalInput.UserType)
        .pipe(map(res => this.cleanFields(res.response)));
    }
    else if (isEmployerJournal(this.journalInput)) {
      this.isEmployerObject = true;
      this.displayedColumns.splice(3, 1); // Removes "Action On" Column
      this.journalData$ = this.employerService
        .GetEmployerJournal(this.journalInput.EmployerId)
        .pipe(map(res => this.cleanEmployerFields(res.Data)));
    }

    else if (isMarketingResourceJournalJournal(this.journalInput)) {
      this.isMarketingResourceJournal = true;
      this.displayedColumns.splice(3, 1); // Removes "Action On" Column
      this.journalData$ = this.markertingResourceService
        .GetJournal(this.journalInput.marketingResourceId)
        .pipe(map(res => this.cleanMarketingResourceFields(res)))
    }
  }


  private cleanDocumentFields(view: ViewJournalResponse[]){
    view = view.sort((a, b) => {
      return new Date(b.Time).getTime() - new Date(a.Time).getTime();
    });

    return view.map(journey => {
      journey.EventType = this.DATA_UPDATED;
      journey.Event = journey.Event?.replace(/<[^>]*>/g, ''); // Removes html tags
      return journey;
    });
  }


  private cleanFields(view: ViewUserJournalResponse[]) {
    view = view.sort((a, b) => {
      return new Date(b.Time).getTime() - new Date(a.Time).getTime();
    });

    return view.map(journey => {
      journey.EventType = this.DATA_UPDATED;
      journey.Event = journey.Event?.replace(/<[^>]*>/g, ''); // Removes html tags
      return journey;
    });
  }

  private cleanMarketingResourceFields(view: MarketingResourceJournalApiResponse) {
    let journal = view.response.sort((a, b) => {
      return new Date(b.Time).getTime() - new Date(a.Time).getTime();
    });

    return journal.map(journey => {
      journey.EventType = this.DATA_UPDATED;
      journey.Event = journey.Event?.replace(/<[^>]*>/g, ''); // Removes html tags
      return journey;
    });
  }

  

  private cleanEmployerFields(view: ViewEmployerJournalData[]) {
    view = view.sort((a, b) => {
      return new Date(b.Time).getTime() - new Date(a.Time).getTime();
    });

    return view.map(journey => {
      journey.EventType = this.DATA_UPDATED;
      journey.Event = journey.Event?.replace(/<[^>]*>/g, ''); // Removes html tags
      return journey;
    });
  }
}

export interface EmployeeJournal {
  employeeId: number;
}
function isEmployeeJournal(journal: object): journal is EmployeeJournal {
  return (journal as EmployeeJournal).employeeId !== undefined;
}

export interface UserJournal {
  UserId: number;
  UserType: AuthenticationUserType;
}
function isUserJournal(journal: object): journal is UserJournal {
  return (journal as UserJournal).UserType !== undefined;
}

export interface EmployerDocumentJournal {
  EmployerId: number;
  EventType: string;
}

function isMasterEmployerJournal(journal: object):journal is EmployerDocumentJournal
{
  return (journal as EmployerDocumentJournal).EventType === "EL";
}

function isNotifyGroupJournal(journal: object): journal is EmployerDocumentJournal
{
  return (journal as EmployerDocumentJournal).EventType === "NG";
}

export interface ProducerDocumentJournal {
  ProducerId: number;
  EventType: string;
}

function isNotifyBrokerJournal(journal: object): journal is ProducerDocumentJournal
{
  return (journal as ProducerDocumentJournal).EventType === "NP";
}


export interface EmployerJournal {
  EmployerId: number;
}
function isEmployerJournal(journal: object): journal is EmployerJournal {
  return (journal as EmployerJournal).EmployerId !== undefined;
}

export interface MarketingResourceJournal {
  marketingResourceId: number;
}
function isMarketingResourceJournalJournal(journal: object): journal is MarketingResourceJournal {
  return (journal as MarketingResourceJournal).marketingResourceId !== undefined;
}
