import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-tutorials',
  templateUrl: './tutorials.component.html',
  styleUrls: ['./tutorials.component.scss']
})
export class TutorialsComponent implements OnInit {

  constructor(
    private router: Router
  ) { }

  buttons = [
    { btnText: 'Login', link: 'https://www.youtube.com/watch?v=zoj7ajQJyjs', externalLink: true },
    { btnText: 'Company Profile', link: 'https://www.youtube.com/watch?v=je5lfRy33gQ', externalLink: true },
    { btnText: 'Enrollment Changes', link: 'https://www.youtube.com/watch?v=DTKvuZ3AeuQ', externalLink: true },
    { btnText: 'Group Census', link: 'https://www.youtube.com/watch?v=MR3xvQ6G590', externalLink: true },
    { btnText: 'Documents and Supplies', link: 'https://www.youtube.com/watch?v=QVPK8oeFiqw', externalLink: true },
    { btnText: 'Invoice and Online Payments', link: 'https://www.youtube.com/watch?v=bysVED8puYU', externalLink: true },
    
  ];

  ngOnInit(): void {

  }

  openLink (link:{btnText: string, link: string, externalLink: boolean} ) {
    if(link.externalLink === true)
    {
      window.open(link.link);
    }else{
      this.router.navigate([link.link]);
    }
  }
}
