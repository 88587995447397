import { Routes, RouterModule } from '@angular/router';
import { BusinessProgramComponent } from './business-program/business-program.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { FaqComponent } from './faq/faq.component';
import { HomeComponent } from './home/home.component';
import { LinksComponent } from './links/links.component';
import { AlliedBenefitSuiteComponent } from './allied-benefit-suite/allied-benefit-suite.component';
import { TutorialsComponent } from './tutorials/tutorials.component';

const routes: Routes = [
  {
    path: '',
    children: [
      { path: 'home', component: HomeComponent },
      { path: 'business-program', component: BusinessProgramComponent },
      { path: 'faq', component: FaqComponent },
      { path: 'links', component: LinksComponent },
      { path: 'contact-us', component: ContactUsComponent },
      { path: 'allied-benefit-suite', component: AlliedBenefitSuiteComponent },
      { path: 'tutorials', component: TutorialsComponent },
    ],
  },
];

export const SiteNavigationRoutes = RouterModule.forChild(routes);
